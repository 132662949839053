import styled from "@emotion/styled";
import dayjs from "dayjs";
import { useState } from "react";
import ReactCalendar from "react-calendar";

function Calendar({
  onChangeDate = (date) => {},
  data = [],
  tileClassName,
  handleDayClick,
}) {
  const [value, onChange] = useState(new Date());
  const today = new Date();

  return (
    <div>
      <StyledCalendar
        // onActiveStartDateChange={(e) => {
        // }}
        onChange={(e) => {
          const isToday = today.setHours(0, 0, 0, 0);
          const isDate = new Date(e).getTime();
          if (isDate > isToday && !!tileClassName) {
            return;
          }

          onChange(e);
          onChangeDate(dayjs(e).format("YYYY-MM-DD"));
        }}
        formatDay={(locale, date) => dayjs(date).format("D")}
        minDetail="month"
        maxDetail="month"
        prev2Label={null}
        next2Label={null}
        prevLabel={<img src="/assets/icons/chevron-left.svg" />}
        nextLabel={<img src="/assets/icons/chevron-right.svg" />}
        showNeighboringMonth={true}
        value={value}
        onClickDay={handleDayClick}
        tileClassName={tileClassName}
        tileContent={({ date, view }) => {
          // date는 현재 타일의 날짜, view는 현재 보기 모드(예: 'month')
          // datesWithData는 데이터가 있는 날짜의 배열
          // const datesWithData = ["2024-01-01", "2024-01-02", "2024-01-03"]; // 예시 데이터
          const dateString = dayjs(date).format("YYYY-MM-DD");
          if (data.includes(dateString)) {
            return (
              <div style={{ position: "relative" }}>
                <DotStyle />
              </div>
            ); // 데이터가 있는 날짜에 대한 커스텀 컨텐츠
          }
        }}
      />
    </div>
  );
}

const DotStyle = styled.div`
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #3279f5;
`;

const StyledCalendar = styled(ReactCalendar)`
  button {
    background: #fff;
    border: none;
    cursor: pointer;
  }

  .react-calendar__navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 8px;
    border-bottom: 1px solid #e2e8f0;
  }

  .react-calendar__navigation__label {
    color: #171923;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 22px;
  }

  .react-calendar__month-view__weekdays {
    margin: 16px 0;
  }

  .react-calendar__month-view__weekdays__weekday {
    color: #718096;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-transform: uppercase;
    text-align: center;

    abbr {
      text-decoration: none;
    }
  }

  .react-calendar__tile {
    height: 38px;
    color: #171923;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    //background: red;
    background: #fff;
    border: none;
    text-align: -webkit-center;

    abbr {
      display: inline-block;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 0.5px;
    }
  }

  .future-date {
    color: #d3d3d3;
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    color: #bcc0c5;
  }

  .react-calendar__tile--active {
    abbr {
      background: #3279f5;
      color: #fff;
      border-radius: 50%;
    }
  }
`;

export default Calendar;
