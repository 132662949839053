import styled from "@emotion/styled";
import dayjs from "dayjs";
import { useCallback, useEffect, useRef, useState } from "react";
import { Header } from "../compontents/Header";
import SideModal from "../compontents/SideModal";
import Modal from "../compontents/Modal";
import Calendar from "../compontents/Calendar";
import ReportPagePrint from "../compontents/print/ReportPagePrint";
import { useReactToPrint } from "react-to-print";
import ImageEnlargedModal from "../compontents/ImageEnlagedModal";
import SiteContainers from "../compontents/SiteContainer";
import axiosInstance from "../library/axios-index";
import AxiosNavigate from "../library/axios-navigate";
import { BlockScrolling, createMarkup } from "../bin/common";
import EmptyContainer from "../compontents/EmptyContainer";

const avatar = `/assets/images/profile.png`;

function Report() {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [opendLaborer, setOpendLaborer] = useState(false);
  const [opendEquipment, setOpendEquipment] = useState(false);
  const [opendMaterial, setOpendMaterial] = useState(false);
  const [opendExport, setOpendExport] = useState(false);
  const [opendImage, setOpendImage] = useState(false);
  const [opendImageIndex, setOpendImageIndex] = useState(0);
  const [imageArr, setImageArr] = useState([]);

  const [sites, setSites] = useState([]);
  const [siteList, setSiteList] = useState([]);
  const [report, setReport] = useState(undefined);
  const [reportEmpty, setReportEmpty] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    dayjs().format("YYYY-MM-DD")
  );
  const [selectedSite, setSelectedSite] = useState(undefined);
  const [calendarData, setCalendarData] = useState([]);

  const [startImageIndex, setStartImageIndex] = useState(0);
  const [endImageIndex, setEndImageIndex] = useState(10);
  const weatherState = {
    Drizzle: "약한비",
    Thunderstorm: "태풍",
    Rain: "비",
    Snow: "눈",
    Clear: "맑음",
    Clouds: "흐림",
    Mist: "안개",
    Smoke: "연기",
    Haze: "안개",
    Dust: "먼지",
    Fog: "안개",
    Sand: "모래",
    Ash: "정보 없음",
    Squall: "돌풍",
    Tornado: "폭풍",
  };

  const handleAllSites = useCallback(async () => {
    const token = localStorage.getItem("admin_token");

    const {
      data: { result },
    } = await axiosInstance.post(`/admin/searchSite`, {
      lang: "ko",
      uuid: "string",
      token,
      search_txt: "",
    });

    setSelectedSite(result[0].site_seq);
    setSites(result);
    setSiteList(result);
    setReport(undefined);
    // setReportEmpty(false);
  }, []);

  // 이미지 팝업시 스크롤제거
  BlockScrolling(opendImage);

  // 현장 검색
  const handleSearchSite = (value) => {
    const list = siteList.filter((e) =>
      e.site_nm
        .toLowerCase()
        .replaceAll(" ", "")
        .includes(value.toLowerCase().replaceAll(" ", ""))
    );
    setSites(list);
  };

  const handleSelectedSite = (e) => {
    setSelectedSite(e);
  };

  const loadReport = async () => {
    if (!selectedSite || !selectedDate) return;

    const token = localStorage.getItem("admin_token");

    const {
      data: { result },
    } = await axiosInstance.post(`/admin/reportInfo`, {
      lang: "ko",
      uuid: "string",
      token,
      site_seq: selectedSite,
      rpt_dt: selectedDate,
    });
    setCalendarData(result.reportDate);

    if (Object.keys(result).length === 1) {
      setReport(undefined);
      setReportEmpty(true);
      return;
    }
    setReport(result);
  };

  useEffect(() => {
    setStartImageIndex(0);
    setEndImageIndex(10);
    loadReport();
  }, [selectedSite, selectedDate]);

  const handleExport = useCallback(() => {
    setOpendExport(true);
  }, []);

  const handlePrev = () => {
    if (startImageIndex > 0) {
      setStartImageIndex((startImageIndex) => startImageIndex - 1);
      setEndImageIndex((endImageIndex) => endImageIndex - 1);
    }
    // alert('handlePrev Image')
  };

  const handleNext = () => {
    if (endImageIndex < report?.images.length) {
      setStartImageIndex((startImageIndex) => startImageIndex + 1);
      setEndImageIndex((endImageIndex) => endImageIndex + 1);
    }
    // alert('handleNext Image')
  };

  const handleImageIndex = (direction) => {
    if (direction === "right") {
      if (opendImageIndex < imageArr?.length - 1) {
        setOpendImageIndex((opendImageIndex) => opendImageIndex + 1);
      }
    } else {
      if (opendImageIndex > 0) {
        setOpendImageIndex((opendImageIndex) => opendImageIndex - 1);
      }
    }
  };

  const handleMoreInfo = useCallback((type) => {
    switch (type) {
      case "laborer":
        setOpendLaborer(true);
        break;
      case "equipment":
        setOpendEquipment(true);
        break;
      case "material":
        setOpendMaterial(true);
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    handleAllSites();
  }, []);

  return (
    <AxiosNavigate>
      <div>
        <Header>공사일보</Header>
        <Content>
          <Info>
            <CalendarContainer style={{ paddingLeft: "16px" }}>
              <Calendar
                onChangeDate={(value) => {
                  setSelectedDate(dayjs(value).format("YYYY-MM-DD"));
                }}
                data={calendarData}
              />
            </CalendarContainer>
            <SiteContainers
              data={{
                pageNm: "report",
                sites,
                handleAllSites,
                handleSearchSite,
                handleSelectedSite,
              }}
            />
          </Info>
          {report ? (
            <Board>
              <BoardPadding>
                <BoardHeader>
                  <div>
                    <Title>{report.site_nm}</Title>
                    <Datetime>
                      {dayjs(report.rpt_dt).format("YYYY-MM-DD")}
                    </Datetime>
                  </div>

                  <Weather>
                    {/* 날씨 데이터 없으시 증간 회색선 제거 */}
                    <Temperature>기온 {report.temperature}도</Temperature>
                    {weatherState[report.weather_state] && (
                      <>
                        <figure></figure>
                        {weatherState[report.weather_state]}
                      </>
                    )}
                  </Weather>
                </BoardHeader>

                <Group>
                  <GroupItem>
                    <GroupHeader>
                      <div>금일업무</div>
                    </GroupHeader>
                    <Textarea
                      placeholder="비어있음"
                      readOnly={true}
                      value={report?.todo || ""}
                    ></Textarea>
                  </GroupItem>

                  <GroupItem>
                    <GroupHeader>
                      <div>명일업무</div>
                    </GroupHeader>
                    <Textarea
                      placeholder="비어있음"
                      readOnly={true}
                      value={report.tobe || ""}
                    ></Textarea>
                  </GroupItem>
                </Group>

                <GroupHeader style={{ marginTop: "30px" }}>
                  <div>작업이미지</div>
                  {report?.images?.length > 0 && (
                    <div>
                      <CircleButtonGroup>
                        <strong style={{ marginRight: "20px" }}></strong>
                        <CircleButton
                          onClick={handlePrev}
                          style={{ marginRight: "6px" }}
                        >
                          <CircleButtonIcon src="/assets/icons/chevron-left.svg" />
                        </CircleButton>
                        <CircleButton onClick={handleNext}>
                          <CircleButtonIcon src="/assets/icons/chevron-right.svg" />
                        </CircleButton>
                      </CircleButtonGroup>
                    </div>
                  )}
                </GroupHeader>
                <ImageList>
                  {report?.images?.length > 0 ? (
                    report?.images
                      ?.slice(startImageIndex, endImageIndex)
                      .map((e, i) => (
                        <ImageCard key={i}>
                          <div
                            onClick={() => {
                              setOpendImage(true);
                              setImageArr(report.images);
                              setOpendImageIndex(startImageIndex + i);
                            }}
                          >
                            <ImageItem key={e.img_seq} src={e.rpt_img} />
                            <div
                              style={{
                                padding: "16px 20px",
                                //minHeight: '127px',
                                backgroundColor: "#fff",
                                marginTop: "8px",
                                display: e.rpt_img_desc || "none",
                              }}
                            >
                              <span
                                dangerouslySetInnerHTML={createMarkup(
                                  e.rpt_img_desc
                                )}
                              ></span>
                            </div>
                          </div>
                        </ImageCard>
                      ))
                  ) : (
                    <EmptyContainer
                      selected={false}
                      style={{ gridColumn: "1/6" }}
                      falseText={`작업이미지가`}
                    />
                  )}
                </ImageList>

                <Group>
                  <GroupItem>
                    <Card>
                      <CardPadding
                        style={{ minHeight: "176px", boxSizing: "border-box" }}
                      >
                        <GroupHeader style={{ marginBottom: "24px" }}>
                          <div>출역 현황</div>
                          <strong onClick={() => handleMoreInfo("laborer")}>
                            자세히 보기
                            <DetailIcon src="/assets/icons/chevron-right.svg" />
                          </strong>
                        </GroupHeader>

                        <Text>
                          <p>
                            총 출역 인원{" "}
                            <strong>{report.laborer[0]?.total_cnt} 명</strong>
                          </p>
                          <p>
                            작업 인력{" "}
                            <strong>{report.laborer[0].direct_cnt} 명</strong>
                          </p>
                          <p>
                            외주 인력{" "}
                            <strong>
                              {report.laborer[0].outsourcing_cnt} 명
                            </strong>
                          </p>
                          <p>
                            기타 인력{" "}
                            <strong>{report.laborer[0].ect_cnt} 명</strong>
                          </p>
                        </Text>
                      </CardPadding>
                    </Card>
                  </GroupItem>

                  <GroupItem>
                    <Card style={{ marginBottom: "16px" }}>
                      <CardPadding>
                        <GroupHeader>
                          <div>장비 출역</div>
                          <strong onClick={() => handleMoreInfo("equipment")}>
                            자세히 보기
                            <DetailIcon src="/assets/icons/chevron-right.svg" />
                          </strong>
                        </GroupHeader>

                        <Text>
                          <p>
                            총 출역 장비{" "}
                            <strong>
                              {report.equipment[0].equipment_cnt} 대
                            </strong>
                          </p>
                        </Text>
                      </CardPadding>
                    </Card>

                    <Card style={{ marginBottom: "16px" }}>
                      <CardPadding>
                        <GroupHeader>
                          <div>자재 반입건</div>
                          <strong onClick={() => handleMoreInfo("material")}>
                            자세히 보기
                            <DetailIcon src="/assets/icons/chevron-right.svg" />
                          </strong>
                        </GroupHeader>

                        <Text>
                          <p>
                            총 반입 자재{" "}
                            <strong>
                              {report.material[0].material_cnt} 건
                            </strong>
                          </p>
                        </Text>
                      </CardPadding>
                    </Card>
                  </GroupItem>
                </Group>

                <Group>
                  <GroupItem>
                    <GroupHeader>
                      <div>현안</div>
                    </GroupHeader>
                    <Textarea
                      placeholder="비어있음"
                      readOnly={true}
                      value={report?.issue || ""}
                    ></Textarea>
                  </GroupItem>

                  <GroupItem>
                    <GroupHeader>
                      <div>대응방안</div>
                    </GroupHeader>
                    <Textarea
                      placeholder="비어있음"
                      readOnly={true}
                      value={report?.react || ""}
                    ></Textarea>
                  </GroupItem>
                </Group>

                <BoardFooter>
                  <Button onClick={handleExport}>출력하기</Button>
                </BoardFooter>
              </BoardPadding>
            </Board>
          ) : (
            <EmptyReport>
              <div>
                <p>
                  <EmptyIcon src="/assets/icons/check-circle-broken.svg" />
                </p>
                {reportEmpty ? (
                  <p>공사일보가 없습니다</p>
                ) : (
                  <>
                    <p>현장목록에서</p>
                    <p>현장명을 검색해주세요</p>
                  </>
                )}
              </div>
            </EmptyReport>
          )}
        </Content>

        <SideModal
          opend={opendLaborer}
          closeModal={() => setOpendLaborer(false)}
          header={"출역 현황 내역"}
        >
          <DescriptionText>
            {dayjs(report?.rpt_dt).format("YYYY년 M월 D일")} 기준
          </DescriptionText>

          {report?.detail &&
            report.detail.laborer.map((e, i) => (
              <SideCard key={i}>
                <CardHeader>
                  <HeaderAvatar
                    style={{ backgroundImage: `url(${e.photo || avatar})` }}
                  />
                  <HeaderTitle>{e.user_nm}</HeaderTitle>
                  <HeaderText>{e.man_day} 공수</HeaderText>
                </CardHeader>
              </SideCard>
            ))}
        </SideModal>

        <SideModal
          opend={opendEquipment}
          closeModal={() => setOpendEquipment(false)}
          header={"장비 출역 내역"}
        >
          <DescriptionText>
            {dayjs(report?.rpt_dt).format("YYYY년 M월 D일")} 기준
          </DescriptionText>

          {report?.detail &&
            report.detail.equipment.map((e, i) => (
              <SideCard key={i}>
                <CardHeader>
                  <HeaderTitle>{e.partner_nm}</HeaderTitle>
                  {/*<HeaderTitle>{e.equip_nm}</HeaderTitle>*/}
                  <HeaderBadge>{e.equip_nm}</HeaderBadge>
                  <HeaderText>{e.effort} 공수</HeaderText>
                </CardHeader>
                <CardItem>
                  <CardLabel>비고</CardLabel>
                  <strong>{e.equip_info}</strong>
                </CardItem>
              </SideCard>
            ))}
        </SideModal>

        <SideModal
          opend={opendMaterial}
          closeModal={() => setOpendMaterial(false)}
          header={"자재 반입 내역"}
        >
          <DescriptionText>
            {dayjs(report?.rpt_dt).format("YYYY년 M월 D일")}기준
          </DescriptionText>
          {report?.detail &&
            report.detail.material.map((e, i) => (
              <SideCard key={i}>
                <CardHeader>
                  <HeaderTitle>{e.corp_nm}</HeaderTitle>{" "}
                  {e.item && <HeaderBadge>{e.item}</HeaderBadge>}
                </CardHeader>
                <CardImageGroup>
                  {e.images.map((e, i) => (
                    <CardImage
                      key={e.img_seq}
                      src={e.rm_img}
                      onClick={() => {
                        setImageArr(report.detail.material[i].images);
                        setOpendImage(true);
                        setOpendImageIndex(i);
                      }}
                    />
                  ))}
                </CardImageGroup>
              </SideCard>
            ))}
        </SideModal>

        <Modal
          opend={opendExport}
          closeModal={() => setOpendExport(false)}
          header={"출력 미리보기"}
          okText={"출력"}
          handleOk={() => {
            handlePrint();
          }}
          widthCheck={"55%"}
        >
          <ReportPagePrint
            ref={componentRef}
            data={{ report }}
            siteSeq={selectedSite}
          />
        </Modal>

        <ImageEnlargedModal
          opend={opendImage}
          closeModal={() => setOpendImage(false)}
          handleImageIndex={handleImageIndex}
          imageIndex={opendImageIndex + 1}
          imageLength={imageArr?.length}
        >
          <div
            style={{
              height: "calc(100vh - 62px)",
              width: "calc(100vw - 200px)",
            }}
          >
            <Header
              style={{
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                fontSize: "25px",
                padding: "0",
                marginBottom: "10px",
              }}
            >
              {" "}
              {opendImageIndex + 1} / {imageArr?.length}
            </Header>
            <div
              style={{
                height: "calc(100% - 78px)",
                position: "relative",
                overflow: "auto",
              }}
            >
              <img
                src={
                  imageArr[opendImageIndex]?.rm_img
                    ? imageArr[opendImageIndex]?.rm_img
                    : imageArr[opendImageIndex]?.rpt_img
                }
                alt="EnlargedImage"
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  position: "absolute",
                  top: "0",
                  bottom: "0",
                  right: "0",
                  left: "0",
                  margin: "auto",
                }}
              />
            </div>
          </div>
        </ImageEnlargedModal>
      </div>
    </AxiosNavigate>
  );
}

const Content = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 992px) {
    flex-direction: row;
  }
`;

const Info = styled.div`
  padding: 0 32px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media only screen and (min-width: 992px) {
    flex-direction: column;
  }
`;

const CalendarContainer = styled.div`
  width: 100%;
  padding: 20px 24px 32px 24px;
  border-radius: 16px;
  border: 1px solid #e2e8f0;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);

  @media only screen and (min-width: 768px) {
    width: 30%;
  }

  @media only screen and (min-width: 992px) {
    width: 280px;
  }
`;
const Button = styled.div`
  display: flex;
  height: 32px;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  background: #3279f5;
  color: #fff;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  cursor: pointer;
`;

const EmptyReport = styled.div`
  border-radius: 16px;
  background: #f7fafc;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    text-align: center;
    margin: 0;
    padding: 0;
    color: #a0aec0;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.2px;
    text-transform: uppercase;
  }

  @media only screen and (max-width: 992px) {
    min-height: 333px;
    margin-top: 24px;
  }
`;

const EmptyIcon = styled.img``;

const Board = styled.div`
  border-radius: 16px;
  background: #f7fafc;
  width: 100%;
  margin-top: 24px;

  @media only screen and (min-width: 992px) {
    margin-top: 0;
  }
`;

const BoardPadding = styled.div`
  padding: 16px 40px;
`;

const BoardHeader = styled.div`
  width: 100%;
  gap: 10px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-direction: column;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

const Title = styled.div`
  color: #2d3748;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`;

const Datetime = styled.div`
  color: #4a5568;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
`;

const Weather = styled.div`
  display: flex;
  width: fit-content;
  padding: 8px 16px;
  align-items: center;
  border-radius: 100px;
  background: #edf2f7;

  figure {
    margin: 0;
    width: 1px;
    height: 16px;
    background: #cbd5e0;
    margin: 0 8px;
  }
`;

const Temperature = styled.div``;

// const WeatherIcon = styled.img``

const Group = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media only screen and (min-width: 992px) {
    flex-direction: row;
  }
`;
const GroupItem = styled.div`
  width: 100%;

  &:nth-of-type(1) {
    //&:nth-child(1){
    margin-right: 18px;
  }
`;

const GroupHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 6px;

  div {
    color: #4a5568;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  strong {
    color: #718096;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
`;

const DetailIcon = styled.img``;

const Textarea = styled.textarea`
  box-sizing: border-box;
  width: 100%;
  height: 124px;
  padding: 8px 12px 8px 12px;
  border-radius: 8px;
  background-color: #fff;
  border: 0px;
  outline: none;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  border: 1px solid #e2e8f0;
  resize: none;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;

  :focus {
  }
`;
const Card = styled.div`
  width: 100%;
  background: #fff;
`;

const CardPadding = styled.div`
  padding: 16px 20px;
`;

const BoardFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 17px;

  div {
    width: 73px;
  }
`;

const ImageList = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin-bottom: 28px;
  gap: 10px;
  min-height: 155px;

  @media only screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const ImageCard = styled.div``;

const ImageItem = styled.img`
  width: 100%;
  border-radius: 4px;
  cursor: pointer;
`;

const Text = styled.div`
  p {
    color: #4a5568;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 137.5% */
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media only screen and (min-width: 768px) {
    p {
      display: block;
    }
  }

  strong {
    color: #171923;
    text-align: right;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 137.5% */
  }
`;

const CircleButtonGroup = styled.div`
  display: flex;
`;

const CircleButton = styled.div`
  border-radius: 100px;
  border: 1px solid #e2e8f0;
  background: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const CircleButtonIcon = styled.img`
  width: 16px;
  height: 16px;
  padding: 8px;
`;

const DescriptionText = styled.div`
  color: #4a5568;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 4px;
`;

const SideCard = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 12px;
  border: 1px solid #e2e8f0;
  background: #fff;
  margin-top: 16px;
`;

const CardHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const HeaderTitle = styled.div`
  color: #171923;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
`;

const HeaderBadge = styled.div`
  color: #1a202c;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  display: flex;
  padding: 2px 6px;
  align-items: center;
  gap: 6px;
  border-radius: 6px;
  background: #edf2f7;
  margin-left: 6px;
`;

const HeaderText = styled.div`
  margin-left: auto;
  color: #718096;
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

const CardItem = styled.div`
  display: flex;
  align-items: center;

  color: #171923;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

const CardLabel = styled.div`
  color: #4a5568;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  width: 50px;
  margin-right: 6px;
`;

const CardImageGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

const CardImage = styled.img`
  width: 70px;
  height: 62px;
  border-radius: 2px;
`;

const HeaderAvatar = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 12px;
  background-position: center;
  background-size: cover;
  margin-right: 8px;
`;

export default Report;
