import React, { forwardRef, useRef, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useMutation } from "@tanstack/react-query";
import { siteAddressListUser } from "../library/axios-query";
const NaverMapLocation = forwardRef((props, ref) => {
  const { data, mode } = props;
  const mapRef = useRef(null);
  const [map, setMap] = useState(null);
  const [infoWindow, setInfoWindow] = useState(null);
  const [marker, setMarker] = useState(null);
  const [logitude, setLogitude] = useState(
    Number(mode === "start" ? data.longitude_start : data.longitude_end)
  ); //세로
  const [latitude, setLatitude] = useState(
    Number(mode === "start" ? data.latitude_start : data.latitude_end)
  );
  // 현장 주소 리스트
  const { mutate: siteAddressListUserMutae, data: siteAddressListUserData } =
    useMutation({
      mutationKey: ["siteAddressListUser"],
      mutationFn: siteAddressListUser,
      onSuccess: (isData) => {
        if (!map) return;
        // 마커 리스트와 정보창 리스트 선언
        const markers = [];
        const infoWindows = [];

        isData.addressList.forEach((element) => {
          const marker = new window.naver.maps.Marker({
            position: new window.naver.maps.LatLng(
              Number(element.latitude),
              Number(element.logitude) // 오타 수정
            ),
            map,
            icon: {
              content: [
                `<div class="cs_map star_map ">`,
                '<div class="map_group _map_group crs">',
                '<div class="map_marker _marker num1 num1_big"> ',
                '<span class="ico _icon"></span>',
                '<span class="shd"></span>',
                "</div>",
                "</div>",
                "</div>",
              ].join(""),
              anchor: new window.naver.maps.Point(12, 30),
            },
          });

          const infoWindow = new window.naver.maps.InfoWindow({
            content: [
              '<div style="padding: 10px; box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 16px 0px;">',
              `   <div style="font-weight: bold; margin-bottom: 5px;">${element?.site_nm}</div>`,
              `   <div style="font-size: 13px;">${element.addr1 || ""} ${
                element.addr2 || ""
              }<div>`,
              "</div>",
            ].join(""),
            maxWidth: 300,
            anchorSize: {
              width: 12,
              height: 10,
            },
            pixelOffset: new window.naver.maps.Point(10, -1),
            borderColor: "#cecdc7",
          });

          const distance = isData.distance.find(
            (item) => item.site_seq === element.site_seq
          )?.distance;
          const circle = new window.naver.maps.Circle({
            map: map, // 지도에 추가
            center: marker.getPosition(), // 원의 중심은 마커의 위치
            radius: distance,
            strokeColor: "#A6A39D", // 원의 테두리 색
            strokeWeight: 1, // 테두리 두께
            fillColor: "##A6A39D", // 원의 채우기 색
            fillOpacity: 0.1, // 채우기 투명도
          });
          // 마커 호버 이벤트 추가
          window.naver.maps.Event.addListener(marker, "mouseover", () => {
            infoWindow.open(map, marker); // 마우스 올리면 InfoWindow 열기
          });

          window.naver.maps.Event.addListener(marker, "mouseout", () => {
            infoWindow.close(); // 마우스 떼면 InfoWindow 닫기
          });

          markers.push(marker);
          infoWindows.push(infoWindow);
        });

        setMarker(markers); // 마커 상태 업데이트
        setInfoWindow(infoWindows); // InfoWindow 상태 업데이트
      },
      onError: (error) => {
        throw error;
      },
    });

  useEffect(() => {
    if (window.naver && window.naver.maps) {
      initializeMap();
    } else {
      console.error("Naver Maps API가 로드되지 않았습니다.");
    }
  }, [data.site_seq]);

  const initializeMap = () => {
    const map = new window.naver.maps.Map(mapRef.current, {
      center: new window.naver.maps.LatLng(latitude, logitude),
      zoom: 18,
      zoomControl: false,
      logoControl: false,
    });

    map.setCursor("pointer");
    setMap(map);

    siteAddressListUserMutae({
      userSeq: data.user_seq,
      date: new Date().toISOString(),
    });

    var marker = new window.naver.maps.Marker({
      position: new window.naver.maps.LatLng(latitude, logitude),
      map: map,
      title: "Green",
      icon: {
        content: [
          `<div class="cs_mapbridge ${mode}_map ">`,
          '<div class="map_group _map_group crs">',
          '<div class="map_marker _marker num1 num1_big"> ',
          '<span class="ico _icon"></span>',
          '<span class="shd"></span>',
          "</div>",
          "</div>",
          "</div>",
        ].join(""),
        anchor: new window.naver.maps.Point(15, 40),
      },
    });

    marker.setTitle(" marker");

    // setMarker(marker);
  };

  return <NaverMapBox ref={mapRef}></NaverMapBox>;
});

const NaverMapBox = styled.div`
  width: 750px;
  height: 500px;

  @media only screen and (max-width: 992px) {
    width: 100%;
    height: calc(100vw - 48px);
  }
`;
export default NaverMapLocation;
