import axiosInstance from "./axios-index";

const token = localStorage.getItem("admin_token");

// admin 팝업 정보 조회
export const getPopupListAdmin = async (data) => {
  return await axiosInstance
    .post(`/admin/pop-up/get/list`, data)
    .then((response) => {
      return response.data.result;
    })
    .catch((error) => {
      throw error;
    });
};

// admin 팝업 정보 생성
export const createPopupAdmin = async (data) => {
  return await axiosInstance
    .post(`/admin/pop-up/create`, data)
    .then((response) => {
      return response.data.result;
    })
    .catch((error) => {
      throw error;
    });
};

// admin 팝업 정보 삭제
export const deletePopupAdmin = async (data) => {
  return await axiosInstance
    .post(`/admin/pop-up/delete`, {
      pop_seq: data,
    })
    .then((response) => {
      return response.data.result;
    })
    .catch((error) => {
      throw error;
    });
};

// admin 팝업 정보 수정
export const updatePopupAdmin = async (data) => {
  return await axiosInstance
    .post(`/admin/pop-up/update`, data)
    .then((response) => {
      return response.data.result;
    })
    .catch((error) => {
      throw error;
    });
};

// 노동자 개별 출역 정보(WEB)
export const getLaborerAttendance = async ({
  user_site_seq,
  site_seq,
  selectedDate,
}) => {
  return await axiosInstance
    .post(`/admin/get/laborer-attendance`, {
      user_site_seq: user_site_seq,
      site_seq: site_seq,
      work_dt: selectedDate,
    })
    .then(({ data: { result } }) => {
      return result;
    })
    .catch(({ response: { data } }) => {
      alert(data.message);
    });
};
// /laborAttendanceModify

// 출역 일보 출역 이력 수정 및 생성 API
export const createLaborerAttendance = async (data) => {
  return await axiosInstance
    .post(`/admin/laborAttendanceModify`, data)
    .then(({ data: { result } }) => {
      return result;
    })
    .catch(({ response: { data } }) => {
      alert(data.message);
    });
};

// 회사 목록 필터 어드민용
export const getCompanyFilter = async (filterType) => {
  return await axiosInstance
    .post(`/admin/get/company-filter`, {
      filterType: filterType,
    })
    .then(({ data: { result } }) => {
      return result;
    })
    .catch(({ response: { data } }) => {
      alert(data.message);
    });
};

// 회원, 근무중 여부 확인 - 주민번호로 검색
export const getLaborSearchInfo = async (id_num) => {
  const data = {
    lang: "ko",
    uuid: "string",
    id_num: id_num,
    //     token
  };
  return await axiosInstance
    .post(`/admin/laborSearchInfo`, data)
    .then(({ data: { result } }) => {
      return result;
    })
    .catch(({ response: { data } }) => {
      alert(data.message);
    });
};

// 근로자정보 - 주민번호, 회원순번으로 검색
export const getLaborInfo = async (data) => {
  const { id_num, user_seq } = data;
  return await axiosInstance
    .post(`/admin/laborInfo`, {
      lang: "ko",
      uuid: "string",
      id_num: id_num,
      user_seq: user_seq,
      //     token
    })
    .then(({ data: { result } }) => {
      return result;
    })
    .catch(({ response: { data } }) => {
      alert(data.message);
    });
};

//FAQ 목록 API
export const getFAQList = async (data) => {
  return await axiosInstance
    .post(`/board/web/get/faq-list`, {
      ...data,
      token,
    })
    .then(({ data: { result } }) => {
      return result;
    })
    .catch(({ response: { data } }) => {
      alert(data.message);
    });
};

//FAQ 글 내용 API
export const getFAQDetail = async (data) => {
  return await axiosInstance
    .post(`/board/web/get/faq-detail`, {
      ...data,
      token,
    })
    .then(({ data: { result } }) => {
      return result;
    })
    .catch(({ response: { data } }) => {
      alert(data.message);
    });
};

// FAQ 등록 API
export const CreateFAQ = async (data) => {
  return await axiosInstance
    .post(`/board/web/create/faq`, data)
    .then(({ data: { result } }) => {
      return result;
    })
    .catch(({ response: { data } }) => {
      alert(data.message);
    });
};

// FAQ 삭제 API
export const deleteFAQ = async (faq_seq) => {
  return await axiosInstance
    .post(`/board/web/delete/faq`, {
      faq_seq: faq_seq,
    })
    .then(({ data: { result } }) => {
      return result;
    })
    .catch(({ response: { data } }) => {
      alert(data.message);
    });
};

// FAQ 수정 API
export const updateFAQ = async (data) => {
  return await axiosInstance
    .post(`/board/web/update/faq`, data)
    .then(({ data: { result } }) => {
      return result;
    })
    .catch(({ response: { data } }) => {
      alert(data.message);
    });
};

// 할인 정책 생성
export const createDiscountRule = async (data) => {
  return await axiosInstance
    .post(`/admin/create/discount-rule`, data)
    .then(({ data: { result } }) => {
      return result;
    })
    .catch(({ response: { data } }) => {
      alert(data.message);
    });
};

// 할인 정책 정보
export const getDiscountRule = async (data) => {
  return await axiosInstance
    .post(`/admin/get/discount-rule`, data)
    .then(({ data: { result } }) => {
      return result;
    })
    .catch(({ response: { data } }) => {
      alert(data.message);
    });
};

// 할인 정책 수정
export const updateDiscountRule = async (data) => {
  return await axiosInstance
    .post(`/admin/update/discount-rule`, data)
    .then(({ data: { result } }) => {
      return result;
    })
    .catch(({ response: { data } }) => {
      alert(data.message);
    });
};

// 할인 정책 삭제
export const deleteDiscountRule = async (dscnt_seq) => {
  return await axiosInstance
    .post(`/admin/delete/discount-rule`, { dscnt_seq: dscnt_seq })
    .then(({ data: { result } }) => {
      return result;
    })
    .catch(({ response: { data } }) => {
      alert(data.message);
    });
};

// 할인 적용 정보
export const getDiscountApply = async (data) => {
  return await axiosInstance
    .post(`/admin/get/apply-discount`, data)
    .then(({ data: { result } }) => {
      return result;
    })
    .catch(({ response: { data } }) => {
      alert(data.message);
    });
};

// 할인 적용 생성
export const createDiscountApply = async (data) => {
  return await axiosInstance
    .post(`/admin/create/apply-discount`, data)
    .then(({ data: { result } }) => {
      return result;
    })
    .catch(({ response: { data } }) => {
      alert(data.message);
    });
};

// 할인 적용 수정
export const updateDiscountApply = async (data) => {
  return await axiosInstance
    .post(`/admin/update/apply-discount`, data)
    .then(({ data: { result } }) => {
      return result;
    })
    .catch(({ response: { data } }) => {
      alert(data.message);
    });
};

// 퍄트너스 게시글/공지사항 가져오기
export const getBoard = async (data) => {
  return await axiosInstance
    .post(`/partners/get/board`, data)
    .then((response) => {
      return response.data.result;
    })
    .catch((error) => {
      console.log("error", error);
      throw error;
    });
};
// 퍄트너스 게시글/공지사항 생성
export const createPartnersBoard = async (data) => {
  return await axiosInstance
    .post(`/partners/create/board`, data)
    .then(({ data: { result } }) => {
      return result;
    })
    .catch(({ response: { data } }) => {
      alert(data.message);
    });
};

// 퍄트너스 게시글/공지사항 수정
export const updatePartnersBoard = async (data) => {
  return await axiosInstance
    .post(`/partners/update/board`, data)
    .then(({ data: { result } }) => {
      return result;
    })
    .catch(({ response: { data } }) => {
      alert(data.message);
    });
};

// 퍄트너스 게시글/공지사항 삭제
export const deletePartnersBoard = async (board_seq) => {
  return await axiosInstance
    .post(`/partners/delete/board`, {
      board_seq: board_seq,
    })
    .then(({ data: { result } }) => {
      return result;
    })
    .catch(({ response: { data } }) => {
      alert(data.message);
    });
};

// 파트너 리스트 가져오기
export const getPartnersUserList = async (data) => {
  return await axiosInstance
    .post(`/admin/partners/get/user-List`, data)
    .then(({ data: { result } }) => {
      return result;
    })
    .catch(({ response: { data } }) => {
      alert(data.message);
    });
};

// 파트너 상세 정보 가져오기
export const getPartnersUserInfo = async (partnerSeq) => {
  return await axiosInstance
    .post(`/admin/partners/get/user-info`, { partnerSeq: partnerSeq })
    .then(({ data: { result } }) => {
      return result;
    })
    .catch(({ response: { data } }) => {
      alert(data.message);
    });
};

//고객사별 파트너 정보 가져오기
export const getPartnersCompany = async (partnerSeq) => {
  return await axiosInstance
    .post(`/admin/partners/get/by-company`, { partnerSeq: partnerSeq })
    .then(({ data: { result } }) => {
      return result;
    })
    .catch(({ response: { data } }) => {
      alert(data.message);
    });
};

//고객사 파트너 정보 수정
export const modifyPartnersCompany = async (data) => {
  return await axiosInstance
    .post(`/admin/partners/update/by-company`, data)
    .then(({ data: { result } }) => {
      return result;
    })
    .catch(({ response: { data } }) => {
      alert(data.message);
    });
};

// 정산 정보 가져오기
export const getPartnersSettlement = async (data) => {
  return await axiosInstance
    .post(`/admin/partners/get/settlement-list`, data)
    .then(({ data: { result } }) => {
      return result;
    })
    .catch(({ response: { data } }) => {
      alert(data.message);
    });
};

// 파트너 수익금액 정산 처리
export const updatePartnersSettlementComplete = async (pgSeq) => {
  return await axiosInstance
    .post(`/admin/partners/settlement-complete`, { pgSeq: pgSeq })
    .then(({ data: { result } }) => {
      return result;
    })
    .catch(({ response: { data } }) => {
      alert(data.message);
    });
};
