import styled from "@emotion/styled";
import { useCallback, useEffect, useRef, useState } from "react";
import { Header } from "../../compontents/Header";
import Modal from "../../compontents/Modal";
import MonthCalendar from "../../compontents/MonthCalendar";
import ExpenseStatusPagePrint from "../../compontents/print/ExpenseStatusPrint";
import { PagingFooter } from "../../compontents/PagingFooter";
import { useReactToPrint } from "react-to-print";
import { HOST_URL } from "../../bin/env";
import dayjs from "dayjs";
import { addCommas, BlockScrolling } from "../../bin/common";
import SiteContainers from "../../compontents/SiteContainer";
import axiosInstance from "../../library/axios-index";
import AxiosNavigate from "../../library/axios-navigate";
import SideModal from "../../compontents/SideModal";
import DownLoadExcel from "../../library/handle-excel";
import EmptyContainer from "../../compontents/EmptyContainer";
import { WidthStyle } from "../../library/styled-components.js";

function ExpenseStatus() {
  const componentRef = useRef();
  const componentImgRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const handleImgPrint = useReactToPrint({
    content: () => componentImgRef.current,
  });
  const [totalConut, setTotalCount] = useState(0);

  const [opendExport, setOpendExport] = useState(false);

  const [sites, setSites] = useState([]);
  const [siteList, setSiteList] = useState([]);
  const [imprests, setImprests] = useState([]);
  const [dailyCashAmt, setDailyCashAmt] = useState({});
  const [amt, setAmt] = useState({
    monthly_input_amt: 0,
    monthly_spend_amt: 0,
    monthly_balance: 0,
    total_input_amt: 0,
    total_spend_amt: 0,
  });
  const [selectedDate, setSelectedDate] = useState(
    dayjs().format("YYYY-MM-DD")
  );
  const [selectedSite, setSelectedSite] = useState(0);
  const [selectedSiteNm, setSelectedSiteNm] = useState("");
  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [selectedImprest, setSelectedImprest] = useState(null);

  // 출력하기 클릭시 증빙 옵션
  const [openPrintModal, setOpenPrintModal] = useState(false);
  const [check, setCheck] = useState();
  // 전체현장 로드
  const handleAllSites = useCallback(async () => {
    const token = localStorage.getItem("admin_token");

    const {
      data: { result },
    } = await axiosInstance.post(`${HOST_URL}/admin/searchSite`, {
      lang: "ko",
      uuid: "string",
      token,
      search_txt: "",
    });

    setSites(result);
    setSiteList(result);
    setSelectedSite(0);
    setImprests([]);
    setAmt({});
  }, []);

  BlockScrolling(openDetailModal);
  BlockScrolling(opendExport);
  // 전체 현장에서 현장 검색
  const handleSearchSite = (value) => {
    const list = siteList.filter((e) =>
      e.site_nm
        .toLowerCase()
        .replaceAll(" ", "")
        .includes(value.toLowerCase().replaceAll(" ", ""))
    );
    setSites(list);
  };

  const handleSelectedSite = (e) => {
    setSelectedSite(e);
  };

  // 출력 버튼 클릭
  const handleOpenPrintModal = useCallback(() => {
    setOpendExport(true);
  }, [selectedSite, selectedDate]);

  // 데이터 불러오기
  const loadExpenseStatus = async (search_txt) => {
    const token = localStorage.getItem("admin_token");

    const {
      data: {
        result: {
          monthly_input_amt,
          monthly_spend_amt,
          monthly_balance,
          total_input_amt,
          total_spend_amt,
          imprests,
          dailyCashAmt,
          totalCount,
        },
      },
    } = await axiosInstance.post(`${HOST_URL}/admin/expenseStatusList`, {
      lang: "ko",
      uuid: "string",
      token,
      search_txt,
      site_seq: selectedSite,
      imp_dt: selectedDate,
    });

    if (!Object.keys(imprests).length) {
      setImprests([]);
      return;
    }

    setImprests(imprests?.slice()?.reverse());
    setDailyCashAmt(dailyCashAmt?.slice()?.reverse());
    setTotalCount(totalCount);
    setAmt({
      monthly_input_amt: monthly_input_amt,
      monthly_spend_amt: monthly_spend_amt,
      monthly_balance: monthly_balance,
      total_input_amt: total_input_amt,
      total_spend_amt: total_spend_amt,
    });

    return imprests;
  };

  // 모달 열기
  const handleOpenModal = async (e) => {
    setOpenDetailModal(true);
    setSelectedImprest(e);
  };

  useEffect(() => {
    handleAllSites();
  }, []);

  useEffect(() => {
    if (selectedSite === undefined) return;

    (async () => {
      await loadExpenseStatus("");
      setSelectedSiteNm(
        sites.find((e) => e.site_seq === selectedSite)?.site_nm
      );
    })();
  }, [selectedDate, selectedSite]);

  const handleExcel = useCallback(() => {
    // const resultImprests = imprests.map((item, i) => [
    //   dayjs(item.imp_dt).format("YYYY년 M월 D일"),
    //   item.class_seq2.class_nm,
    //   item.imp_type === "I" ? addCommas(item.imp_amt) : "",
    //   item.imp_type === "S" && item.method === "M0007D0001"
    //     ? addCommas(item.imp_amt)
    //     : "",
    //   item.imp_type === "S" && item.method === "M0007D0002"
    //     ? addCommas(item.imp_amt)
    //     : "",
    //   item.imp_type === "S" && item.method === "M0007D0003"
    //     ? addCommas(item.imp_amt)
    //     : "",
    //   addCommas(dailyCashAmt[i].amt),
    //   item.imp_img1 && item.imp_img1,
    //   item.imp_img2 && item.imp_img2,
    //   item.imp_img3 && item.imp_img3,
    //   item.imp_img4 && item.imp_img4,
    //   item.imp_img5 && item.imp_img5,
    // ]);
    // console.log(resultImprests);
    // console.log(resultImprests);

    DownLoadExcel({
      txt: "전도금지출내역",
      date: dayjs(selectedDate).format("YYYY-MM"),
      site: selectedSiteNm,
      type: 1,
    });
  }, [imprests, amt]);

  return (
    <AxiosNavigate>
      <div>
        <Header>전도금 지출내역</Header>
        <Content>
          <Info>
            <CalendarContainer style={{ paddingLeft: "16px" }}>
              <MonthCalendar
                onChangeDate={(date) => {
                  setSelectedDate(date);
                }}
              />
            </CalendarContainer>
            <SiteContainers
              data={{
                sites,
                handleAllSites,
                handleSearchSite,
                handleSelectedSite,
              }}
            />
          </Info>
          {imprests.length ? (
            <Board>
              <Panel>
                <div>
                  <BoardHeader>
                    <h2>{selectedSiteNm}</h2>
                  </BoardHeader>
                </div>
                <div>
                  <ButtonGroup>
                    <Button onClick={handleExcel}>
                      <Icon src="/assets/icons/excel.svg" /> 엑셀파일
                    </Button>
                    <Button
                      onClick={() => {
                        handleOpenPrintModal();
                        setOpenPrintModal(false);
                      }}
                    >
                      <Icon src="/assets/icons/printer.svg" /> 출력하기
                    </Button>
                    <Button
                      onClick={() => {
                        handleOpenPrintModal();
                        setOpenPrintModal(true);
                      }}
                    >
                      <Icon src="/assets/icons/printer.svg" /> 출력하기(증빙
                      포함)
                    </Button>
                  </ButtonGroup>
                </div>
              </Panel>

              <TableContainer>
                <TableWrapper>
                  <Table id="report-table">
                    <thead>
                      <tr>
                        <th rowSpan={2} width={"100px"}>
                          사용일자
                        </th>
                        <th rowSpan={2}>적요</th>
                        <th rowSpan={2}>입금</th>
                        <th colSpan={3}>지출</th>
                        <th rowSpan={2}>현금잔액</th>
                        <th rowSpan={2}>비고</th>
                      </tr>
                      <tr>
                        <th>법인카드</th>
                        <th>현금</th>
                        <th className={"border"}>계좌이체</th>
                      </tr>
                    </thead>
                    <tbody>
                      {imprests.map((e, i) => (
                        <tr key={i}>
                          <td>
                            <div style={{ padding: "8px 0" }}>
                              {dayjs(e.imp_dt).format("YYYY년 M월 D일")}
                            </div>
                          </td>
                          <td>
                            <div
                              style={{
                                minWidth: "152px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              {e.class_seq2.class_nm}
                              <Button
                                className={"remove"}
                                onClick={() => handleOpenModal(e)}
                                style={{ boxSizing: "border-box" }}
                              >
                                상세보기
                              </Button>
                            </div>
                          </td>
                          <WidthStyle
                            className={"table-right"}
                            width={"90px"}
                            space={false}
                          >
                            {e.imp_type === "I" ? addCommas(e.imp_amt) : ""}
                          </WidthStyle>
                          <WidthStyle
                            className={"table-right"}
                            width={"90px"}
                            space={false}
                          >
                            {e.imp_type === "S" && e.method === "M0007D0001"
                              ? addCommas(e.imp_amt)
                              : ""}
                          </WidthStyle>
                          <WidthStyle
                            className={"table-right"}
                            width={"90px"}
                            space={false}
                          >
                            {e.imp_type === "S" && e.method === "M0007D0002"
                              ? addCommas(e.imp_amt)
                              : ""}
                          </WidthStyle>
                          <WidthStyle
                            className={"table-right"}
                            width={"90px"}
                            space={false}
                          >
                            {e.imp_type === "S" && e.method === "M0007D0003"
                              ? addCommas(e.imp_amt)
                              : ""}
                          </WidthStyle>
                          <WidthStyle
                            className={"table-right"}
                            width={"90px"}
                            space={false}
                          >
                            {addCommas(dailyCashAmt[i].amt)}
                          </WidthStyle>
                          <WidthStyle
                            style={{ textAlign: "left" }}
                            width={"300px"}
                            space={true}
                          >
                            {e.desc}
                          </WidthStyle>
                        </tr>
                      ))}
                    </tbody>
                    <thead>
                      <tr>
                        <th>월계</th>
                        <th></th>
                        <th className={"table-right"}>
                          {addCommas(amt.monthly_input_amt)}
                        </th>
                        <th colSpan={3} className={"table-right"}>
                          {addCommas(amt.monthly_spend_amt)}
                        </th>
                        <th className={"table-right"}>
                          {addCommas(amt.monthly_balance)}
                        </th>
                        <th></th>
                      </tr>
                      <tr>
                        <th>누계</th>
                        <th></th>
                        <th className={"table-right"}>
                          {addCommas(amt.total_input_amt)}
                        </th>
                        <th colSpan={3} className={"table-right"}>
                          {addCommas(amt.total_spend_amt)}
                        </th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                  </Table>
                </TableWrapper>
              </TableContainer>

              <PagingFooter></PagingFooter>

              {selectedImprest == null ? null : (
                <SideModal
                  opend={openDetailModal}
                  closeModal={() => setOpenDetailModal(false)}
                  header={"상세보기"}
                  widthCheck={"50%"}
                >
                  <ModalContent>
                    <h5>
                      {dayjs(selectedImprest.imp_dt).format("YYYY년 M월 D일")}
                    </h5>
                    <hr style={{ border: "none", borderTop: "1px solid" }} />
                    <h6>지급방식</h6>
                    {selectedImprest.imp_type === "I" ? (
                      <h5>입금</h5>
                    ) : selectedImprest.method === "M0007D0001" ? (
                      <h5>법인카드</h5>
                    ) : selectedImprest.method === "M0007D0002" ? (
                      <h5>현금</h5>
                    ) : selectedImprest.method === "M0007D0003" ? (
                      <h5>계좌이체</h5>
                    ) : (
                      <h5>지출</h5>
                    )}
                    <hr
                      style={{
                        border: "none",
                        borderTop: "2px solid",
                        marginBottom: "16px",
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h5>금액</h5>
                      <h5>{addCommas(selectedImprest.imp_amt)}</h5>
                    </div>
                    <hr style={{ border: "none", borderTop: "2px solid" }} />
                    <h6>적요</h6>
                    <h5>{selectedImprest.class_seq2.class_nm}</h5>
                    <h6>비고</h6>
                    <h5>{selectedImprest.desc}</h5>
                    <h6>사용자</h6>
                    <h5>{selectedImprest.imp_user_nm}</h5>
                    <hr style={{ border: "none", borderTop: "2px solid" }} />

                    {selectedImprest.imp_img1 !== null ? (
                      <img src={selectedImprest.imp_img1} alt="" />
                    ) : null}
                    {selectedImprest.imp_img2 !== null ? (
                      <img src={selectedImprest.imp_img2} alt="" />
                    ) : null}
                    {selectedImprest.imp_img3 !== null ? (
                      <img src={selectedImprest.imp_img3} alt="" />
                    ) : null}
                    {selectedImprest.imp_img4 !== null ? (
                      <img src={selectedImprest.imp_img4} alt="" />
                    ) : null}
                    {selectedImprest.imp_img5 !== null ? (
                      <img src={selectedImprest.imp_img5} alt="" />
                    ) : null}
                  </ModalContent>
                </SideModal>
              )}
            </Board>
          ) : (
            <EmptyContainer
              selected={selectedSite === 0}
              falseText={"전도금 지출내역이"}
            ></EmptyContainer>
          )}
        </Content>

        <Modal
          opend={opendExport}
          closeModal={() => setOpendExport(false)}
          header={"출력 미리보기"}
          okText={"출력"}
          handleOk={() => {
            handlePrint();
          }}
          widthCheck={"55%"}
        >
          <ExpenseStatusPagePrint
            ref={componentRef}
            data={{
              imprests: imprests,
              dailyCashAmt: dailyCashAmt,
              amt: amt,
              date: selectedDate,
              site: selectedSiteNm,
            }}
            siteSeq={selectedSite}
            imgNY={openPrintModal}
          />
        </Modal>
      </div>
    </AxiosNavigate>
  );
}

const Content = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 992px) {
    flex-direction: row;
  }
`;

const Info = styled.div`
  padding: 0 32px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media only screen and (min-width: 992px) {
    flex-direction: column;
  }
`;

const CalendarContainer = styled.div`
  width: 100%;
  padding: 20px 24px 32px 24px;
  border-radius: 16px;
  border: 1px solid #e2e8f0;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);

  @media only screen and (min-width: 768px) {
    width: 30%;
  }

  @media only screen and (min-width: 992px) {
    width: 280px;
  }
`;

const Button = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
  min-height: 32px;
  padding: 0 9px;
  justify-content: center;
  align-items: center;
  gap: 9px;
  border-radius: 6px;
  border: 1.125px solid #e2e8f0;
  background: #fff;

  color: #4a5568;
  leading-trim: both;
  text-edge: cap;
  font-size: 14px;
  cursor: pointer;

  margin-top: 5px;
`;

const ModalContent = styled.div`
  h5 {
    margin: 0;
    margin-bottom: 8px;
    padding: 8px 0;
    letter-spacing: 0.2px;
    color: #2d3748;
    font-weight: 500;
  }

  h6 {
    margin: 0;
    padding: 0;
    padding-top: 8px;
    letter-spacing: 0.2px;
    color: #2d3748;
    font-weight: 800;
  }

  img {
    width: 100%;
    margin-top: 16px;
  }
`;

const EmptyReport = styled.div`
  border-radius: 16px;
  background: #f7fafc;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    text-align: center;
    margin: 0;
    padding: 0;
    color: #a0aec0;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.2px;
    text-transform: uppercase;
  }

  @media only screen and (max-width: 992px) {
    min-height: 333px;
    margin-top: 24px;
  }
`;

const EmptyIcon = styled.img``;

const Board = styled.div`
  border-radius: 16px;
  background: #f7fafc;
  width: 100%;
  margin-top: 24px;

  @media only screen and (min-width: 992px) {
    margin-top: 0;
  }
`;

const Panel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 32px;
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
`;

const Icon = styled.img`
  width: 16px;
`;

const TableContainer = styled.div`
  padding: 0 32px;
  margin-top: 28px;
`;

const TableWrapper = styled.div`
  border: 1px solid #cbd5e0;
  border-radius: 8px;
  overflow: hidden;
  overflow-x: auto;
`;

const Table = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;

  th,
  td {
    text-align: left;
    padding: 2px;
    font-size: 13px;
    font-weight: 300;
    border-right: 1px solid #cbd5e0;
    border-bottom: 1px solid #cbd5e0;
    white-space: nowrap;
  }

  th {
    background-color: #e2e8f0;
    text-align: center;
  }

  tr:last-child td {
    border-bottom: none;
  }

  td:first-of-type {
    text-align: center;
  }

  td {
    background: #fff;
    text-align: center;
  }

  thead:last-of-type tr:last-of-type th {
    border-bottom: none;
  }

  tbody td img {
    width: 100px;
    height: 100px;
  }

  .img {
    display: none;
  }
`;

const PageGroup = styled.div`
  display: flex;
  justify-content: center;

  ul {
    display: flex;
    padding: 0;
    list-style: none;
  }

  li a {
    display: inline-block;
    cursor: pointer;
    color: #000;
    padding: 6px 12px;
    text-decoration: none;
    font-size: 12px;
  }

  li.active a {
    color: #fff;
    background-color: #3279f5 !important;
    border-radius: 4px;
  }

  li:hover:not(.active) a {
    background-color: #ebf2fe !important;
    border-radius: 4px;
  }
`;

const SelectWrapper = styled.div`
  display: flex;
  align-items: center;

  span {
    color: #718096;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-right: 6px;
  }
`;

const BoardHeader = styled.div`
  margin-bottom: 14px;
  margin-top: 28px;

  h2,
  h5 {
    margin: 0;
  }

  h5 {
    margin-top: 4px;
  }
`;

export default ExpenseStatus;
