import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";

function CustomTimePicker({
  ampm,
  value,
  time,
  disabled,
  onChange,
  workNightTime = false,
}) {
  const t =
    time !== null
      ? time.split(":")
      : ampm === "am"
      ? ["00", "00"]
      : ["12", "00"];
  const [hour, setHour] = useState(t[0]);
  const [minute, setMinute] = useState(t[1]);

  const hours = [
    ...Array(value.includes("_end") ? (workNightTime ? 7 : 13) : 12).keys(),
  ]
    .map((n) => n + (ampm === "pm" ? (workNightTime ? 0 : 12) : 0))
    .map(String)
    .map((n) => n.padStart(2, "0"));
  const minutes = [
    ...Array(
      value.includes("_end") &&
        ((ampm === "am" && hour == 12) || (ampm == "pm" && hour == 24))
        ? 1
        : 6
    ).keys(),
  ]
    .map(String)
    .map((n) => (n * 10).toString().padStart(2, "0"));

  useEffect(() => {
    onChange(
      value,
      `${hour}:${
        value.includes("_end") &&
        ((ampm === "am" && hour == 12) || (ampm == "pm" && hour == 24))
          ? "00"
          : minute
      }:00`
    );
  }, [hour, minute]);

  return (
    <div style={{ width: "100%" }}>
      <InputBox
        value={hour}
        disabled={disabled}
        onChange={(e) => setHour(e.target.value)}
      >
        {hours.map((hour, index) => (
          <option key={index} value={hour}>
            {hour}
          </option>
        ))}
      </InputBox>
      <span> : </span>
      <InputBox
        value={minute}
        disabled={disabled}
        onChange={(e) => setMinute(e.target.value)}
      >
        {minutes.map((minute, index) => (
          <option key={index} value={minute}>
            {minute}
          </option>
        ))}
      </InputBox>
    </div>
  );
}

const InputBox = styled.select`
  width: 60px;
  box-sizing: border-box;
  border: 1px solid #d1d5db;
  outline: none;

  padding: 6px 6px;
  margin: 4px;

  align-items: center;
  border-radius: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export default CustomTimePicker;
