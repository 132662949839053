import styled from "@emotion/styled";

export const PagingFooter = styled.div`
  display: flex;
  justify-content: center;
  //margin: auto;
  position: relative;
  // padding: 0 32px;
  height: 38px;
  margin-top: 20px;
  align-items: center;

  ul {
    display: flex;
    padding: 0;
    z-index: 99;
    list-style: none;
  }

  li a {
    //display: inline-block;
    cursor: pointer;
    color: #000;
    width: 30px;
    height: 30px;
    align-items: center;
    justify-content: center;
    display: flex;
    //padding: 6px 12px;
    text-decoration: none;
    font-size: 12px;
  }

  li.active a {
    color: #fff;
    background-color: #3279f5 !important;
    border-radius: 4px;
  }

  li:hover:not(.active) a {
    background-color: #ebf2fe !important;
    border-radius: 4px;
  }

  > span {
    color: #ff3838;
    font-size: 14px;
    position: absolute;
    left: 10px;
    top: -15px;
  }

  @media only screen and (max-width: 992px) {
    justify-content: flex-start;
    margin-left: 32px;

    > div {
      width: 100%;

      > span {
        display: none;
      }

      &:last-child {
        justify-content: flex-end;
      }
    }
  }
`;
