import styled from "@emotion/styled";
import Modal from "react-modal";
import { ModalButton } from "./ModalButton";
import { useWindowSize } from "react-use";

const customStyles = {
  overlay: {
    zIndex: "9998",
    background: "rgba(0,0,0,0.4)",
  },
  content: {
    top: 0,
    right: 0,
    left: "auto",
    width: "90%",
    maxWidth: "440px",
    height: "100%",
    borderRadius: 0,
    padding: 0,
  },
};

function SideModal({ children, opend, closeModal, header, buttons, ...props }) {
  const { width } = useWindowSize();
  return (
    <Modal
      isOpen={opend}
      onRequestClose={closeModal}
      shouldCloseOnOverlayClick={false}
      style={{
        overlay: customStyles.overlay,
        content: {
          ...customStyles.content,
          ...props.style,
          //   'maxWidth': '500px',
          ...(width < 992
            ? {
                width: "100%",
                maxWidth: "100%",
              }
            : {}),
        },
      }}
      ariaHideApp={false}
    >
      <Padding>
        <Header>
          {width < 992 ? (
            <>
              <div>
                <SidebarButton
                  onClick={closeModal}
                  alt="bars"
                  src="/assets/icons/chevron-left.svg"
                />
              </div>
              <div style={{ width: "50%" }}>
                {typeof header === "string" ? (
                  <h4
                    style={{
                      color: "#171923",
                      fontSize: "18px",
                      margin: 0,
                      whiteSpace: "nowrap",
                    }}
                  >
                    {header}
                  </h4>
                ) : (
                  header
                )}
              </div>
              <div></div>
            </>
          ) : (
            <>
              <div>
                {typeof header === "string" ? (
                  <h4
                    style={{
                      color: "#171923",
                      fontSize: "18px",
                      margin: 0,
                      whiteSpace: "nowrap",
                    }}
                  >
                    {header}
                  </h4>
                ) : (
                  header
                )}
              </div>
              <div></div>
            </>
          )}
          <ButtonGroup>
            {buttons}
            <ModalButton onClick={closeModal}>
              <img src="/assets/icons/left-icon.svg" />
            </ModalButton>
          </ButtonGroup>
        </Header>

        <Content>{children}</Content>
      </Padding>
    </Modal>
  );
}

const Padding = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  padding: 32px;
  padding-bottom: 0;

  & > div {
    width: 33.3%;

    &:nth-of-type(2) {
      //&:nth-child(2) {
      text-align: center;
    }
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 40% !important;
`;

const Content = styled.div`
  height: 100%;
  overflow-y: auto;
  padding: 0 32px;
  box-sizing: border-box;
`;

const SidebarButton = styled.img`
  cursor: pointer;
`;

export default SideModal;
