import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useMutation } from "@tanstack/react-query";
import styled from "@emotion/styled";
import { siteAddressList } from "../library/axios-query";
import SelectedFilter from "./SelectedFilter";
import SelectedFilterBox from "./SelectedFilterBox";

const zoomLable = [
  { distance: 50, zoom: 18 },
  { distance: 200, zoom: 18 },
  { distance: 500, zoom: 16 },
  { distance: 700, zoom: 15 },
  { distance: 1000, zoom: 15 },
  { distance: 1001, zoom: 14 },
];
const NaverMapOverlay = forwardRef((props, ref) => {
  const { siteSeq, mode } = props;
  const mapRef = useRef(null);
  const [map, setMap] = useState(null);
  const [infoWindow, setInfoWindow] = useState(null);
  const [marker, setMarker] = useState(null);
  const [addressFilter, setAddressFilter] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState({});
  // 현장 주소 리스트
  const { mutate: getSiteAddress, data: getSiteAddressList } = useMutation({
    mutationKey: ["siteAddressList"],
    mutationFn: siteAddressList,
    onSuccess: (isData) => {
      const map = new window.naver.maps.Map(mapRef.current, {
        center: new window.naver.maps.LatLng(
          Number(isData.addressList[0]?.latitude),
          Number(isData.addressList[0]?.logitude)
        ),
        zoom: 15,
        zoomControl: false,
        logoControl: false,
      });

      map.setCursor("pointer");
      setMap(map);

      isData.addressList.forEach((element) => {
        const marker = new window.naver.maps.Marker({
          position: new window.naver.maps.LatLng(
            Number(element.latitude),
            Number(element.logitude) // 오타 수정
          ),
          map,
          title: `${element.addr1} ${element.addr2 || ""}`,
          icon: {
            content: [
              `<div class="cs_map star_map ">`,
              '<div class="map_group _map_group crs">',
              '<div class="map_marker _marker num1 num1_big"> ',
              '<span class="ico _icon"></span>',
              '<span class="shd"></span>',
              "</div>",
              "</div>",
              "</div>",
            ].join(""),
            anchor: new window.naver.maps.Point(12, 30),
          },
        });

        const circle = new window.naver.maps.Circle({
          map: map, // 지도에 추가
          center: marker.getPosition(), // 원의 중심은 마커의 위치
          radius: isData.distance,
          strokeColor: "#A6A39D", // 원의 테두리 색
          strokeWeight: 1, // 테두리 두께
          fillColor: "##A6A39D", // 원의 채우기 색
          fillOpacity: 0.1, // 채우기 투명도
        });
      });
      setSelectedAddress({
        label: `${isData.addressList[0].addr1} ${
          isData.addressList[0].addr2 || ""
        }`,
        value: isData.addressList[0]?.addr_seq,
      });
      setAddressFilter(
        isData.addressList.map((item) => ({
          label: `${item.addr1} ${item.addr2 || ""}`,
          value: item.addr_seq,
        }))
      );
    },
    onError: (error) => {
      throw error;
    },
  });

  useEffect(() => {
    if (window.naver && window.naver.maps) {
      getSiteAddress(siteSeq);
    } else {
      console.error("Naver Maps API가 로드되지 않았습니다.");
    }
  }, [siteSeq]);

  const handlePosition = useCallback(() => {
    if (!selectedAddress) return;
    const selected = getSiteAddressList?.addressList.find(
      (item) => item.addr_seq === selectedAddress.value
    );
    if (!selected) return;
    const LatLng = new window.naver.maps.LatLng(
      Number(selected.latitude),
      Number(selected.logitude)
    );

    map.panTo(LatLng);
  }, [selectedAddress, getSiteAddressList, map]);

  useEffect(() => {
    handlePosition();
  }, [handlePosition]);
  return (
    <div ref={ref}>
      <SelectedFilterBox
        style={{
          width: "505px",
          height: "38px",
        }}
        onChange={(event) => {
          setSelectedAddress(event);
        }}
        // placeholder={selectedAddress.label}
        options={addressFilter}
        value={selectedAddress}
      ></SelectedFilterBox>
      <NaverMapBox ref={mapRef}></NaverMapBox>
    </div>
  );
});
const NaverMapBox = styled.div`
  width: 750px;
  height: 500px;
  margin-top: 7px;
  @media only screen and (max-width: 992px) {
    width: 100%;
    height: calc(100vw - 48px);
  }
`;
export default NaverMapOverlay;
