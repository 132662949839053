import React, { forwardRef } from "react";
import Checkbox from "react-custom-checkbox";
import styled from "@emotion/styled";

const CustomChecked = forwardRef((props, ref) => {
  const { data, onChange, style, size = 18 } = props;

  return (
    <CheckComponent
      ref={ref}
      checked={data.isCheck}
      onChange={onChange}
      icon={
        data.isCheck && (
          <CheckboxIcon>
            <CheckboxIconInner>
              <CheckboxIconImage src="/assets/icons/ignore.svg" />
            </CheckboxIconInner>
          </CheckboxIcon>
        )
      }
      size={size}
      borderColor={data.isCheck ? "#3279F5" : "#E2E8F0"}
      style={{ overflow: "hidden" }}
      label={
        <div
          style={{
            paddingLeft: "7px",
            boxSizing: "border-box",
            cursor: "pointer",
            ...style,
          }}
        >
          {data.text}
        </div>
      }
    />
  );
});
const CheckComponent = styled(Checkbox)`
  input[type="checkbox"] {
    display: none;
  }
  label {
    box-sizing: border-box;
  }
`;
const CheckboxIcon = styled.div`
  display: flex;
  flex: 1;
  background-color: #3279f5;
  align-self: stretch;
  cursor: pointer;

  input {
    display: contents;
  }
`;

const CheckboxIconInner = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CheckboxIconImage = styled.img`
  width: 70%;
  height: 70%;
`;

export default CustomChecked;
