import styled from "@emotion/styled";
import { useCallback, useEffect, useState } from "react";

function SiteContainers(props) {
  const data = props.data;
  const [updateData, setUpdateData] = useState(true);
  const [selectedId, setSelectedId] = useState("");
  const onClickSelectedItem = (selectedItemId) => {
    data?.sites?.map((e) => {
      const siteItem = document.getElementById("siteItem" + e.site_seq);
      if (siteItem.id === selectedItemId) {
        siteItem.style.background = `rgba(0, 0, 0, 0.15)`;
      } else {
        siteItem.style.background = `rgba(0, 0, 0, 0)`;
      }
      return true;
    });
  };

  // 공사일보 등에서 초기 화면을 첫번째 선택된 현장으로 만들기 위해.
  const firstSiteItemSelect = useCallback(() => {
    if (updateData) {
      if (data?.sites.length > 0) {
        if (data.hasOwnProperty("pageNm") && data.pageNm == "report") {
          const siteItem = document.getElementById(
            "siteItem" + data.sites[0].site_seq
          );
          siteItem.style.background = `rgba(0, 0, 0, 0.15)`;
          setUpdateData(false);
        }
      }
    }
  }, [data]);

  useEffect(() => {
    firstSiteItemSelect();
    onClickSelectedItem(selectedId);
  }, [data]);

  return (
    <SiteContainer>
      <SiteHeader>
        <SiteLabel>현장목록</SiteLabel>
        <Button
          onClick={() => {
            data.handleAllSites();
          }}
          style={{ background: "#3279F5", color: "#FFF" }}
        >
          전체 목록
        </Button>
      </SiteHeader>
      <SiteSearch>
        <SiteSearchIcon src="/assets/icons/search-sm.svg" />
        <SiteSearchInput
          placeholder="현장명 검색"
          onKeyDown={(e) =>
            e.key === "Enter" && data.handleSearchSite(e.target.value)
          }
        />
      </SiteSearch>

      {data.sites.length ? (
        <SiteList>
          {data.sites.map((e, i) => (
            <SiteItem
              key={i}
              id={"siteItem" + e.site_seq}
              onClick={(event) => {
                // onClickSelectedItem(event.target.id);
                data.handleSelectedSite(e.site_seq);
                setSelectedId(event.target.id);
                // test(event.target.id);
              }}
            >
              {e.site_nm}
              {e.end_yn == "Y" ? " (종료)" : ""}
            </SiteItem>
          ))}
        </SiteList>
      ) : (
        <SiteEmptyList>
          <div>
            <p>현장명을</p>
            <p>검색해주세요</p>
          </div>
        </SiteEmptyList>
      )}
    </SiteContainer>
  );
}

export default SiteContainers;

const SiteContainer = styled.div`
  width: 100%;
  height: 333px;
  border-radius: 16px;
  border: 1px solid #e2e8f0;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
  padding: 20px 24px 32px 24px;
  overflow: hidden;
  margin-top: 24px;

  @media only screen and (min-width: 768px) {
    width: 52%;
    margin-top: 0;
    margin-left: 24px;
  }

  @media only screen and (min-width: 992px) {
    width: 280px;
    padding: 20px;
    margin-top: 24px;
    margin-left: 0;
  }
`;

const SiteLabel = styled.div`
  color: #0f2552;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 133.333% */
  letter-spacing: 0.18px;
  text-transform: uppercase;
`;

const SiteHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SiteSearch = styled.div`
  display: flex;
  align-items: center;
  margin-top: 18px;
  margin-bottom: 12px;
  background: #f7fafc;
  border-radius: 8px;
`;

const SiteSearchIcon = styled.img`
  padding-left: 12px;
`;

const SiteSearchInput = styled.input`
  box-sizing: border-box;
  width: 100%;
  border: 0px;
  outline: none;

  padding: 12px;

  background: #f7fafc;

  color: #171923;
  leading-trim: both;
  text-edge: cap;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  :focus {
  }
`;

const SiteList = styled.div`
  overflow: auto;
  height: 75%;
`;

const SiteItem = styled.div`
  color: #4a5568;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding: 8px;
  cursor: pointer;

  :hover {
    background: rgba(0, 0, 0, 0.05) !important;
  }
`;

const SiteEmptyList = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 105px);

  p {
    color: #a0aec0;
    margin: 0;
    padding: 0;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 137.5% */
    letter-spacing: 0.16px;
    text-transform: uppercase;
  }
`;

const Button = styled.div`
  display: flex;
  height: 32px;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  background: #fff;
  color: #4a5568;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  cursor: pointer;
`;
