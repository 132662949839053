import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useMutation } from "@tanstack/react-query";
import { getApprovalLine } from "../library/axios-query";
import { useRecoilState } from "recoil";
import { companyState } from "../library/recoil/atoms/companyState";
import axiosInstance from "../library/axios-index";

const PrintSignComponent = ({ seq }) => {
  const [companyInformation, setCompanyInformation] =
    useRecoilState(companyState);
  const [teamSeq, setTeamSeq] = useState(null);

  // 회사 정보> 결재양식 설정(부서별 직급 지정 정보)
  const { mutate: getApprovalLineMutate, data: getApprovalLineData } =
    useMutation({
      mutationKey: ["getApprovalLine"],
      mutationFn: getApprovalLine,
      onSuccess: (data) => {},

      onError: (error) => {
        console.log(error);
      },
    });

  useEffect(() => {
    if (!seq) return;
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("admin_token");
        const {
          data: {
            result: { sites, totalCount },
          },
        } = await axiosInstance.post(`/admin/siteList`, {
          lang: "ko",
          uuid: "string",
          token,
          search_txt: "",
          page: 1,
          limit: 10000,
          filter: "A",
        });

        const team = sites.find((e) => {
          return e.site_seq === seq;
        });

        if (team) {
          setTeamSeq(team.team_seq);
          getApprovalLineMutate({
            corp_seq: companyInformation.corp_seq,
            team_seq: team.team_seq,
          });
        }
      } catch (error) {
        console.error("Error fetching site list:", error);
      }
    };

    fetchData();
  }, [companyInformation, seq]);

  return (
    <CheckGroup
      count={getApprovalLineData && getApprovalLineData[teamSeq]?.length}
    >
      {getApprovalLineData &&
        teamSeq &&
        getApprovalLineData[teamSeq]?.map((e) => (
          <div key={e.rank_seq}>
            <span>{e.rank_nm}</span>
            <div></div>
          </div>
        ))}
    </CheckGroup>
  );
};
const CheckGroup = styled.div`
  display: ${({ count }) => (count ? (count < 8 ? "flex" : "grid") : "none")};
  column-gap: 0px;
  grid-template-columns: repeat(7, 1fr);

  > div {
    //width: 43px;
    width: ${({ count }) => (count < 6 ? "60px" : "43px")};
    height: ${({ count }) => (count < 6 ? "60px" : "43px")};
    overflow: hidden;
    border: 1px solid;
    margin-right: -1px;
    margin-bottom: -1px;
    box-sizing: border-box;
  }

  span {
    //background-color: #EDF2F7;
    line-height: 13px;
    text-align: center;
    display: block;
    color: #4a5568;
    font-size: 7px;
    border-bottom: 1px solid;
    font-weight: 500;
  }

  @media print {
    > div {
      box-sizing: border-box;
      -webkit-print-color-adjust: exact;
      background-color: #fff;
      overflow: hidden;
      margin-right: -1px;
      box-sizing: border-box;
      margin-bottom: -1px;
    }
  }
`;
export default PrintSignComponent;
