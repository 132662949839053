import dayjs from "dayjs";
import { useEffect, useLayoutEffect, useRef } from "react";
import { RoleType } from "./enum";
import moment from "moment";

export const addCommas = (number) => {
  return number ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0";
};

export const numberOnly = (e) => {
  if (e.target.value.match(/[^0-9]/g)) {
    e.target.value = e.target.value.replace(/[^0-9.]/g, "");
  }
};
export const addIdNumHyphen = (number) => {
  return number ? number.toString().replace(/^(\d{6})(\d{7})$/, `$1-$2`) : "";
};

export const addPhoneHyphen = (number) => {
  return number
    ? number.toString().replace(/^(\d{2,3})-(\d{3,4})-(\d{4})$/, `$1-$2-$3`)
    : "";
};
export const stringNumberToInt = (stringNumber) => {
  return stringNumber
    ? parseInt(stringNumber.toString().replace(/[^0-9]/g, ""))
    : 0;
};

/* 주민등록번호 혹은 외국인등록번호로 현제 나이 계산 */
export const idNumToCurrentOld = (idNum) => {
  let birthDay = null;
  if (idNum) {
    birthDay = idNum.slice(0, 6);

    //주민등록 번호 뒷자리 시작 숫자
    const idNumBackStartNum = idNum.slice(7, 8);
    if (
      idNumBackStartNum === "1" ||
      idNumBackStartNum === "5" ||
      idNumBackStartNum === "2" ||
      idNumBackStartNum === "6"
    ) {
      //19xx년생 (등록번호 뒷자리 1,5,2,6 시작)
      birthDay = "19" + birthDay;
    } else {
      //20xx년생 (3,7,4,8 시작)
      birthDay = "20" + birthDay;
    }

    // let old = dayjs().format('YYYY') - dayjs(birthDay).format('YYYY')
    const today = dayjs();
    birthDay = dayjs(birthDay);
    let age = today.year() - birthDay.year(); // 연도 기반 초기 나이 계산
    const monthDifference = today.month() - birthDay.month();
    const dayDifference = today.date() - birthDay.date();

    // 생일이 아직 지나지 않았다면 나이에서 1 빼기
    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
      age--;
    }

    return age ? (age < 0 ? 0 : age) : 0;
  }
};

const useDidMountEffect = (func: any, deps: any) => {
  const didMount = useRef(false);
  useEffect(() => {
    if (didMount.current) func();
    else {
      didMount.current = true;
    }
  }, deps);
};
export default useDidMountEffect;

export const checkPasswordRule = (password) => {
  return !/^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,15}$/.test(password);
};

export const checkRole = (menuUrl, role, checkType) => {
  if (!role?.admin) {
    let findRole;
    if (checkType == RoleType.READ) {
      findRole = role.readRole.find((e) => e.menu_url === menuUrl);
    } else if (checkType == RoleType.WRITE) {
      findRole = role.writeRole.find((e) => e.menu_url === menuUrl);
    }
    if (!findRole) {
      alert("권한이 없습니다. 관리자에게 문의해 주시기 바랍니다.");
      return false;
    }
  }
  return menuUrl;
};

export const checkInput = (value, required = true) => {
  if (required === false) {
    if (String(value).length === 0) {
      return false;
      // return true;
    }
  }
  const valueTrim = String(value).trim();
  const valueTrimLength = valueTrim.length;
  // 앞뒤 공백 제거후 값이 없으면 true로 반환
  if (valueTrimLength === 0) {
    return true;
  }
  return false;
};

export const isEmptyObject = (obj) => {
  if (Object.keys(obj).length === 0 && obj.constructor === Object) {
    return false;
  }
  return true;
};
// 스크롤 비활성화
export const BlockScrolling = (openedDetail) => {
  useLayoutEffect(() => {
    if (openedDetail) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [openedDetail]);
};

// 페이지 스타일을 초기화하는 함수

export const resetPageStyle = () => {
  const styleElement = document.getElementById("printPageStyle");
  if (styleElement) {
    styleElement.remove();
  }
};

export const setPageStyle = (style) => {
  let styleElement = document.getElementById("printPageStyle");
  if (!styleElement) {
    styleElement = document.createElement("style");
    styleElement.id = "printPageStyle";
    document.head.appendChild(styleElement);
  }
  styleElement.innerHTML = style;
};

// 중복 제거후 옵션 제작
export const setRemoveDuplicates = (data, firstLabel) => {
  let array = [];
  data?.map((e) => {
    array.push({ label: e.corp_nm, value: e.corp_seq });
  });
  let company = [...new Set(array.map(JSON.stringify))].map(JSON.parse);
  company.unshift({ value: null, label: firstLabel });
  return company;
};
export const parse = (dateString) => {
  return dateString?.replace(
    /(\d{4})년\s(\d{2})월\s(\d{2})일/g,
    (match, year, month, day) => {
      // 앞의 0을 제거하여 정수로 변환
      return `${year}년 ${parseInt(month, 10)}월 ${parseInt(day, 10)}일`;
    }
  );
};

// 데이터 필터
export const dataFilter = (
  id,
  data,
  before = "dtl_cd_id",
  after = "dtl_cd_nm"
) => {
  if (!data) return;
  const filterData = data?.filter((e, i) => {
    if (e[before] === id) {
      return e[after];
    }
  });
  if (filterData.length === 0) return;
  return filterData[0][after];
};

export const getAllDatesInMonth = (dateString) => {
  // 주어진 날짜를 Date 객체로 변환
  let date = new Date(dateString);

  // 주어진 날짜의 년도와 월을 가져옴
  let year = date.getFullYear();
  let month = date.getMonth();

  // 해당 월의 첫날과 마지막 날 계산
  let firstDay = new Date(year, month, 1);
  let lastDay = new Date(year, month + 1, 0);

  // 결과를 저장할 배열
  let dates = [];

  // 첫날부터 마지막 날까지 날짜를 배열에 추가
  for (let day = firstDay; day <= lastDay; day.setDate(day.getDate() + 1)) {
    dates.push(moment(day).format("YYYY-MM-DD")); // 새로운 Date 객체를 배열에 추가 (참조가 아닌 값 복사)
  }

  return dates;
};
// 마크업 생성
export const createMarkup = (content) => {
  const formattedText = content?.replace(/\n/g, "<br />");
  return { __html: formattedText };
};
// html > text 변경
export const htmlToText = (htmlString) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = htmlString; // HTML 문자열을 DOM으로 변환
  const hasImgTag = tempDiv.querySelector("img") !== null;

  return hasImgTag || tempDiv.innerText || tempDiv.textContent; // 텍스트만 추출
};

// 파일 다운
export const downloadFile = (blob, filename) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  setTimeout(() => {
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }, 0);
};

// blob에서 에러 파싱
export const parseBlobError = async (error) => {
  const blob = error.response.data;
  const text = await blob.text();
  try {
    return JSON.parse(text);
  } catch (e) {
    return { message: "An unknown error occurred" };
  }
};

export const handleDownloadFiles = async (fileUrl, fileName) => {
  try {
    const parseUrl = fileUrl.replace(
      "https://bucket-9uc28v.s3.ap-northeast-2.amazonaws.com/",
      ""
    );

    const proxyUrl = "https://variable-peafowl-ilsaram-0ee58bff.koyeb.app";
    const response = await fetch(`${proxyUrl}/${fileUrl}`, {
      method: "GET",
      headers: {
        // 'Content-Type': 'application/octet-stream',  // 또는 해당 MIME 타입 설정
        // 'Content-Type': 'application/vnd.ms-excel'
      },
    });
    if (!response.ok) {
      throw new Error("파일 다운로드 실패");
    }

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url); // 메모리 해제
  } catch (error) {
    console.error("다운로드 중 오류 발생:", error);
  }
};

// 주민등록번호 공개 or 비공개
export const idNumReveal = (value, boolean) => {
  if (boolean) {
    return value;
  } else {
    return value?.substr(0, 8)?.padEnd(14, "*");
  }
};
