import styled from '@emotion/styled';
import {Header} from '../compontents/Header';
import MultiProgress from 'react-multi-progress';
import {BarElement, CategoryScale, Chart as ChartJS, LinearScale, Tooltip} from 'chart.js';
import {Bar} from 'react-chartjs-2';
import React, {useEffect, useState} from 'react';
import {addCommas} from '../bin/common';
import axiosInstance from '../library/axios-index';
import AxiosNavigate from '../library/axios-navigate';
import {useCookies} from 'react-cookie';
import NoticeModal from '../compontents/NoticeModal';
import {useMutation} from '@tanstack/react-query';
import {getAdminPopupData} from '../library/axios-query';


const options = {
    responsive: true,
    scales: {
        x: {
            grid: {

                display: false
            }
        },
        y: {
            ticks: {
                display: false,
            },
            grid: {
                display: false
            }
        }
    }
};

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip
);

function Dashboard() {

    const [selectedType, setSelectedType] = useState('I');
    const [cookies, setCookies] = useCookies(['notice']);
    const [siteInfo, setSiteInfo] = useState([]);
    const [noticeOpened, setNoticeOpened] = useState(false);
    const [popupData, setPopupData] = useState([]);
    const [data, setData] = useState({
        today_attendance_total: 0,
        month_labor_cost: 0,
        today_imprest_cost: 0,
        month_imprest_total_cost: 0,
        siteInfo: []
    });

    //공지 팝업 오픈
    const {mutate: getAdminPopupDataItem, data: getPopupData} = useMutation({
        mutationKey: ['getAdminPopupData'],
        mutationFn: getAdminPopupData,
        onSuccess: (data) => {
            const getPopupFilterData = data.filter(item => item.type === 'M0023D0001' || item.content2 !== null);
            setPopupData(getPopupFilterData);
        }
    });

    useEffect(() => {

        (async () => {

            try {
                const token = localStorage.getItem('admin_token');
                if (token) {
                    const {data: {result}} = await axiosInstance.post(`/admin/dashboard`, {
                        lang: 'ko',
                        uuid: 'string',
                        token
                    });

                    // 진행중인 현장으로 초기 설정
                    const progressSite = result.siteInfo.filter((site) => {
                        return site.end_yn == 'N';
                    });
                    setSiteInfo(progressSite);
                    setData(result);
                }

            } catch (error) {
                alert('현장 정보를 가져오는데 실패하였습니다. 다시 시도해 주세요');
            }

        })();


        getAdminPopupDataItem();
    }, []);

    const handleSiteInfo = (status) => {

        // 진행중 버튼 눌렀을 시
        if (status == 'I') {
            const sites = data.siteInfo.filter((site) => {
                return site.end_yn == 'N';
            });
            setSiteInfo(sites);
        }
        // 마감 버튼 눌렀을 시
        else if (status == 'E') {
            const sites = data.siteInfo.filter((site) => {
                return site.end_yn == 'Y';
            });
            setSiteInfo(sites);
        }
    };


    return (
        <AxiosNavigate>
            <div>
                <Header>
                    <div>
                        대시보드
                    </div>

                    <ButtonGroup>
                        <Button
                            onClick={() => {
                                setSelectedType('I');
                                handleSiteInfo('I');
                            }}
                            style={{
                                background: selectedType === 'I' ? '#3279F5' : '#F7FAFC',
                                color: selectedType === 'I' ? '#fff' : '#4A5568'
                            }}>
                            진행중
                        </Button>
                        <Button
                            onClick={() => {
                                setSelectedType('E');
                                handleSiteInfo('E');
                            }}
                            style={{
                                background: selectedType === 'E' ? '#3279F5' : '#F7FAFC',
                                color: selectedType === 'E' ? '#fff' : '#4A5568'
                            }}>
                            종료
                        </Button>
                    </ButtonGroup>
                </Header>

                <TitleBoardGroup>
                    <TitleBoard>
                        <figure>
                            <h5>금일 총 출역인원</h5>
                            <h2>{addCommas(data.today_attendance_total)}<small> 명</small></h2>
                        </figure>
                    </TitleBoard>

                    <TitleBoard>
                        <figure style={{background: '#EDF2F7'}}>
                            <h5>금월 예상노무비</h5>
                            <h2>{addCommas(data.month_labor_cost)}<small> 원</small></h2>
                        </figure>
                    </TitleBoard>

                    <TitleBoard>
                        <figure>
                            <h5>금일 전도금 지출액</h5>
                            <h2>{addCommas(data.today_imprest_cost)}<small> 원</small></h2>
                        </figure>
                    </TitleBoard>

                    <TitleBoard>
                        <figure style={{background: '#EDF2F7'}}>
                            <h5>금월 전도금 지출 총액</h5>
                            <h2>{addCommas(data.month_imprest_total_cost)}<small> 원</small></h2>
                        </figure>
                    </TitleBoard>
                </TitleBoardGroup>

                <Tips>
                    <strong><span style={{background: '#AA95FC'}}></span>노무비</strong>
                    <strong><span style={{background: '#29B0FF'}}></span>외주비</strong>
                    <strong><span style={{background: '#0ACA40'}}></span>장비비</strong>
                    <strong><span style={{background: '#2E2E2E'}}></span>경비</strong>
                </Tips>

                <ChartGroup>
                    {!!siteInfo.length && siteInfo.map((e, i) => <Chart key={i}>
                        <ChartPadding>
                            <ChartHeaderGroup>
                                <ChartHeader>
                                    <figure style={{overflow: 'auto'}}>
                                        <h4>현장명</h4>
                                        <h5>{e.site_nm}</h5>
                                    </figure>
                                </ChartHeader>
                                <ChartHeader>
                                    <figure>
                                        <h4>계약금액</h4>
                                        <h5>{addCommas(e.contract_cost)}원</h5>
                                    </figure>
                                </ChartHeader>
                                <ChartHeader>
                                    <figure>
                                        <h4>투입액 누계</h4>
                                        <h5>{addCommas(e.effort_cost)}원</h5>
                                    </figure>
                                </ChartHeader>
                            </ChartHeaderGroup>
                            <ChartContent>
                                <MultiProgress
                                    height="16px"
                                    backgroundColor="#EDF2F7"
                                    elements={[
                                        {
                                            value: e.graph.labor,
                                            color: '#AA95FC'
                                        },
                                        {
                                            value: e.graph.sub_partner,
                                            color: '#29B0FF'
                                        },
                                        {
                                            value: e.graph.equipment,
                                            color: '#0ACA40'
                                        },
                                        {
                                            value: e.graph.expense,
                                            color: '#2E2E2E'
                                        }
                                    ]}
                                />
                            </ChartContent>
                        </ChartPadding>
                        <BarChartGroup>

                            <BarChart>
                                <h5>노무비 추이 (12개월)</h5>

                                <div>
                                    <Bar options={options} data={{
                                        labels: [
                                            '01',
                                            '02',
                                            '03',
                                            '04',
                                            '05',
                                            '06',
                                            '07',
                                            '08',
                                            '09',
                                            '10',
                                            '11',
                                            '12'
                                        ],
                                        datasets: [
                                            {
                                                label: '노무비 추이',
                                                borderRadius: 10,
                                                data: e.chart.labor,
                                                backgroundColor: '#3279F5'
                                            }
                                        ],
                                    }}/>
                                </div>
                            </BarChart>

                            <BarChart>
                                <h5>지출액 추이 (12개월)</h5>

                                <div>
                                    <Bar options={options} data={{
                                        labels: [
                                            '01',
                                            '02',
                                            '03',
                                            '04',
                                            '05',
                                            '06',
                                            '07',
                                            '08',
                                            '09',
                                            '10',
                                            '11',
                                            '12'
                                        ],
                                        datasets: [
                                            {
                                                label: '지출액 추이',
                                                borderRadius: 10,
                                                data: e.chart.expense,
                                                backgroundColor: '#FFA3A3'
                                            }
                                        ],
                                    }}/>
                                </div>
                            </BarChart>

                        </BarChartGroup>
                    </Chart>)}

                </ChartGroup>
            </div>
            {
                popupData?.length > 0 && (<NoticeModal
                    header={'공지사항'}
                    okText={'확인'}
                    noticeData={popupData}
                    widthCheck={'500px'}
                ></NoticeModal>)
            }

        </AxiosNavigate>);
}

const TitleBoardGroup = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 24px;
    padding: 0 24px;
`;

const TitleBoard = styled.div`
    width: 50%;

    figure {
        margin: 12px 8px;
        padding: 24px;
        border-radius: 8px;
        background: #EBF2FE;
    }

    h5, h2 {
        margin: 0;
    }

    h5 {
        margin-bottom: 24px;
        color: #2D3748;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
    }

    h2 {
        text-align: right;
        color: #171923;
        text-align: right;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 40px;
        letter-spacing: -0.64px;

        small {
            color: #171923;
            text-align: right;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 32px;
            letter-spacing: -0.48px;
        }
    }

    @media only screen and (min-width: 1384px) {

        h2 {
            font-size: 32px;
        }
    }

    @media only screen and (min-width: 992px) {
        width: 25%;
    }

    @media only screen and (max-width: 570px) {

        figure {
            padding: 14px;
        }

        h5 {
            font-size: 11px;
        }

        h2 {
            font-size: 16px;

            small {
                font-size: 11px;
            }
        }
    }
`;

const ChartGroup = styled.div`
    padding: 0 32px;
`;

const Chart = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    min-height: 232px;
    border-radius: 8px;
    border: 1px solid #E2E8F0;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
    margin-bottom: 18px;
    padding: 16px 32px;

    @media only screen and (min-width: 1402px) {
        flex-wrap: nowrap;
    }
`;

const ChartHeaderGroup = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 54px;
`;

const ChartHeader = styled.div`
    width: 100%;

    figure {
        margin: 0;
        padding: 24px 20px;
        border-radius: 12px;
        background: #F7FAFC;
        height: 104px;
        margin-bottom: 12px;
    }

    h4 {
        color: #4A5568;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
    }

    h5 {
        color: #171923;
        text-align: right;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        margin: 10px 0 0 0;
    }

    h4, h5 {
        text-align: left;
    }

    @media only screen and (min-width: 992px) {
        width: 33.3%;

        &:not(:last-child) {
            figure {
                margin-right: 12px;
            }
        }

        &:not(:first-of-type) h4, h5 {
            text-align: right;
        }
    }
`;

const ChartContent = styled.div`
    text-align: center;
    margin: auto;
    width: 100%;
`;

const ChartPadding = styled.div`
    width: 100%;

    @media only screen and (min-width: 992px) {
        width: 100%;
    }
`;

const BarChartGroup = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
    width: 100%;

    @media only screen and (min-width: 1402px) {
        width: 600px;
        margin-left: 16px;
    }
`;

const BarChart = styled.div`
    width: 50%;

    h5 {
        color: #4A5568;
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        margin: 0;
        margin-bottom: 84px;
    }
`;

const Tips = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 32px;
    margin-bottom: 10px;

    span {
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-right: 6px;
    }

    strong {
        display: flex;
        align-items: center;
        color: #1A202C;
        font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        margin: 0;
        margin-left: 12px;
    }
`;

const ButtonGroup = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    //margin-right: 32px;  
`;
const Button = styled.div`
    display: flex;
    width: 40px;
    height: 24px;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    border: 1px solid #718096;
    background: #F7FAFC;
    color: black;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    cursor: pointer;
`;

export default Dashboard;
