import React, { forwardRef, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { outsourcingCompanyState } from "../../library/recoil/atoms/outsourcingCompanyState";
import { InputBox } from "../../library/styled-components";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { addCommas, numberOnly } from "../../bin/common";
import styled from "@emotion/styled";
import ImageUploader from "react-image-upload";
import axios from "axios";
import { HOST_URL } from "../../bin/env";
import { useQueryClient } from "@tanstack/react-query";

const fieldsOrder = [
  "cmpny_nm",
  "contract_type",
  "contract_amt",
  "manager",
  "phone",
];

const OutsourcingForm = forwardRef((props, ref) => {
  const queryClient = useQueryClient();
  const outsourcingState = useRecoilValue(outsourcingCompanyState);
  const setOutsourcingState = useSetRecoilState(outsourcingCompanyState);
  const { fromState, handleForm, getSubPartnerData } = props;
  const [contractReadOnly, setContractReadOnly] = useState(
    outsourcingState?.contract_type !== "G" ? true : false
  );
  const {
    register,
    control,
    getValues,
    handleSubmit,
    setFocus,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });

  useEffect(() => {
    //  console.log(getSubPartnerData);
    if (fromState) {
      // 협력사 추가
      setValue("contract_type", "G");
      setContractReadOnly(false);
    } else {
      // 협력사 변경
      setValue("acct_photo", outsourcingState.acct_photo);
      setValue("reg_photo", outsourcingState.reg_photo);
      setValue("reg_num1", outsourcingState.reg_num.slice(0, 3));
      setValue("reg_num2", outsourcingState.reg_num.slice(3, 5));
      setValue("reg_num3", outsourcingState.reg_num.slice(5, 10));
      setValue(
        "contract_amt",
        addCommas(Number(outsourcingState.contract_amt))
      );
      setValue("contract_type", outsourcingState?.contract_type);
      setValue("manager", outsourcingState.manager);
      setValue("phone", outsourcingState.phone);
      setValue("cmpny_nm", outsourcingState.cmpny_nm);
    }
  }, []);

  const onError = (errors) => {
    const errorKeys = Object.keys(errors);
    if (errorKeys.length > 0) {
      const firstError = fieldsOrder.find((key) => errorKeys.includes(key));
      setFocus(firstError);

      if (errors[firstError].type === "noSpaces") {
        setValue(firstError, "");
      }
      alert(errors[firstError]?.message || "Form validation error");
    }
  };

  const onSubmit = async (data) => {
    const bizRegNum = data.reg_num1 + data.reg_num2 + data.reg_num3;

    // 중복된 사업자등록번호 체크
    if (bizRegNum !== "" && bizRegNum !== outsourcingState?.reg_num) {
      const isDuplicate = getSubPartnerData.some(
        (e) => e.reg_num === bizRegNum
      );
      if (isDuplicate) {
        setFocus("reg_num1");
        alert("중복된 사업자등록번호가 존재합니다");
        return;
      }
    }

    const modify = {
      partner_seq: outsourcingState.partner_seq || "",
      site_seq: outsourcingState.site_seq,
      partner_type: "S",
      class_seq: outsourcingState.class_seq || null,
      cmpny_nm: data.cmpny_nm,
      partner_cd: outsourcingState.partner_cd || "",
      contract_type: data.contract_type,
      contract_amt: Number(data.contract_amt?.toString().replaceAll(",", "")),
      manager: data.manager,
      phone: data.phone,
      reg_num: data.reg_num1 + data.reg_num2 + data.reg_num3,
      reg_photo: data.reg_photo || "",
      acct_photo: data.acct_photo || "",
      stop_yn: "",
      complete_yn: "",
    };

    handleForm(modify);
  };
  const handleDeleteFile = async (formName) => {
    setValue(formName, "");
  };
  const handleUploadFile = async (formName, e) => {
    const formData = new FormData();
    formData.append("files", e.file);

    const {
      data: { result },
    } = await axios({
      method: "post",
      url: `${HOST_URL}/upload`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    const img = result[0];

    setValue(formName, img);
  };
  return (
    <FieldGroup
      ref={ref}
      className="new-outsourcing-form"
      onSubmit={handleSubmit(onSubmit, onError)}
    >
      <InputBox fulled>
        <h6>
          업체명/개인명 <span>*</span>
        </h6>
        <input
          name="cmpny_nm"
          type="text"
          {...register("cmpny_nm", {
            validate: {
              register: (value) =>
                value !== "" || "업체명/개인명을 입력해 주세요",
              noSpaces: (value) =>
                value.trim() !== "" || "업체명/개인명을 입력해 주세요",
            },
          })}
        />
      </InputBox>

      <InputBox
        style={{
          display: "flex",
          alignItems: "center",
          columnGap: "15px",
          flexDirection: "row",
        }}
      >
        <Controller
          name="contract_type"
          control={control}
          rules={{ required: "계약방식을 선택해주새요" }}
          render={({ field }) => (
            <>
              <FormControl
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  columnGap: "10px",
                }}
                {...field}
              >
                <h6 style={{ marginBottom: "0px" }}>
                  계약 방식 <span>*</span>
                </h6>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={getValues("contract_type") || ""}
                  onChange={(e) => {
                    if (e.target.value === "G") {
                      setContractReadOnly(false);
                    } else {
                      setContractReadOnly(true);
                      setValue("contract_amt", 0);
                    }
                  }}
                >
                  <FormControlLabel
                    value="G"
                    control={<Radio size="small" />}
                    label="총액"
                    sx={{
                      fontSize: "14px",
                    }}
                  />
                  <FormControlLabel
                    value="U"
                    control={<Radio size="small" />}
                    label="단가"
                    sx={{
                      fontSize: "14px",
                    }}
                  />
                </RadioGroup>
              </FormControl>
            </>
          )}
        />
      </InputBox>

      <InputBox fulled>
        <h6>
          총계약 금액 (총액계약 시) <span>*</span>
        </h6>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "5px",
            columnGap: "7px",
          }}
        >
          <input
            // value={fromState === false ? addCommas(getValues('contract_amt')) : ''}
            defaultValue={
              fromState === false ? addCommas(getValues("contract_amt")) : 0
            }
            name="contract_amt"
            {...register("contract_amt", {
              required: !contractReadOnly && "총계약 금액을 입력해 주세요",
            })}
            onChange={(e) => {
              numberOnly(e);
              e.target.value = addCommas(parseInt(e.target.value));
            }}
            readOnly={contractReadOnly}
          />
          원
        </div>
      </InputBox>
      <InputBox fulled>
        <h6>
          담당자명 <span>*</span>
        </h6>
        <input
          name="manager"
          type="text"
          {...register("manager", {
            validate: {
              register: (value) => value !== "" || "담당자명을 입력해 주세요",
              noSpaces: (value) =>
                value.trim() !== "" || "담당자명을 입력해 주세요",
            },
          })}
        />
      </InputBox>
      <div style={{ marginBottom: "7px" }}>
        <InputBox fulled>
          <h6>
            연락처 <span>*</span>
          </h6>
          <input
            name="phone"
            type="text"
            {...register("phone", {
              required: "연락처를 입력해 주세요",
              pattern: {
                value: /(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{3})/,
                message: "연락처 형식이 올바르지 않습니다.",
              },
            })}
            onChange={(e) => {
              numberOnly(e);
              setValue("phone", e.target.value);
            }}
            maxLength={"12"}
            minLength={"7"}
          />
        </InputBox>
        <em>
          세금계산서 발행이 필요한 경우 사업자등록증과 통장사본을 입력해야
          합니다.
        </em>
      </div>
      <InputBox fulled>
        <h6>사업자등록번호</h6>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "5px",
            columnGap: "7px",
          }}
        >
          <input
            name="reg_num1"
            type="text"
            maxLength={3}
            {...register("reg_num1", {})}
            onChange={(e) => {
              numberOnly(e);
              if (e.target.value.length >= e.target.maxLength) {
                setFocus("reg_num2");
              }
            }}
          />
          -
          <input
            name="reg_num2"
            type="text"
            maxLength={2}
            {...register("reg_num2", {})}
            onChange={(e) => {
              numberOnly(e);
              if (e.target.value.length >= e.target.maxLength) {
                setFocus("reg_num3");
              }
            }}
          />
          -
          <input
            name="reg_num3"
            type="text"
            maxLength={5}
            {...register("reg_num3", {})}
            onChange={(e) => {
              numberOnly(e);
            }}
          />
        </div>
      </InputBox>

      <Fields imgBox={"true"}>
        <Uploader>
          <h6>사업자등록증</h6>
          <ImageBox>
            <ImageUploader
              onFileRemoved={() => handleDeleteFile("stamp_img")}
              onFileAdded={(e) => handleUploadFile("stamp_img", e)}
              deleteIcon={
                watch("stamp_img") ? (
                  <ImageDelete className="delete-label">
                    <ImageButton style={{ color: "#ff3838" }}>
                      <Icon src="/assets/icons/trash-04.svg" /> 삭제
                    </ImageButton>
                  </ImageDelete>
                ) : (
                  <div></div>
                )
              }
              uploadIcon={
                !watch("stamp_img") ? (
                  <>
                    <ImagePreview>
                      <img src="/assets/icons/image-03.svg" alt={"upload"} />
                      <ImageButton>이미지 업로드</ImageButton>
                    </ImagePreview>
                    <br />
                  </>
                ) : (
                  <>
                    <ImageWrapper className="upload-label">
                      <ImageButton>이미지 재업로드</ImageButton>
                    </ImageWrapper>
                  </>
                )
              }
            />
            <ImageDefault
              src={watch("stamp_img")}
              style={
                getValues("stamp_img")
                  ? { width: "inherit" }
                  : { width: "unset" }
              }
            />
            {watch("stamp_img") && <div className="hover-wrapper"></div>}
          </ImageBox>
        </Uploader>

        <Uploader>
          <h6>통장 사본</h6>
          <ImageBox>
            <ImageUploader
              onFileRemoved={() => handleDeleteFile("acct_photo")}
              onFileAdded={(e) => handleUploadFile("acct_photo", e)}
              deleteIcon={
                watch("acct_photo") ? (
                  <ImageDelete className="delete-label">
                    <ImageButton style={{ color: "#ff3838" }}>
                      <Icon src="/assets/icons/trash-04.svg" /> 삭제
                    </ImageButton>
                  </ImageDelete>
                ) : (
                  <div></div>
                )
              }
              uploadIcon={
                !watch("acct_photo") ? (
                  <>
                    <ImagePreview>
                      <img src="/assets/icons/image-03.svg" alt={"upload"} />
                      <ImageButton>이미지 업로드</ImageButton>
                    </ImagePreview>
                    <br />
                  </>
                ) : (
                  <>
                    <ImageWrapper className="upload-label">
                      <ImageButton>이미지 재업로드</ImageButton>
                    </ImageWrapper>
                  </>
                )
              }
            />
            <ImageDefault
              src={watch("acct_photo")}
              style={
                getValues("acct_photo")
                  ? { width: "inherit" }
                  : { width: "unset" }
              }
              onerror="this.style.display='none'"
            />
            {watch("acct_photo") && <div className="hover-wrapper"></div>}
          </ImageBox>
        </Uploader>
      </Fields>
    </FieldGroup>
  );
});
const Icon = styled.img`
  margin-right: 6px;
`;

const ImageBox = styled.div`
  width: 100%;
  display: block;
  padding-bottom: 100%;
  position: relative;
  box-sizing: border-box;
  border-radius: 6px;
  border: 1px dashed #cbd5e0;
  overflow: hidden;

  > * {
    position: absolute;
  }

  .uploader__file,
  .delete-label,
  .upload-label {
    display: none;
  }

  .uploader__container {
    width: 100%;
    height: 100%;
  }

  &:hover {
    .hover-wrapper {
      background: rgba(0, 0, 0, 0.3);
    }

    .uploader__file,
    .delete-label,
    .upload-label {
      display: block;
    }
  }
`;
const ImagePreview = styled.div`
  display: flex;
  padding: 40px 0;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  justify-content: center;
  flex: 1 0 0;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  align-self: stretch;
  position: relative;
  background: #fff;
  //margin: 0 12px;
  cursor: pointer;

  img {
    top: 50px;
    position: absolute;
  }

  div {
    position: absolute;
    bottom: 50px;
  }
`;

const ImageWrapper = styled.div`
  position: absolute;
  left: 15px;
  z-index: 999;
  top: 50%;
  transform: translateY(-50%);
`;

const ImageDelete = styled.div`
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  //left: 50%;left
  z-index: 999;
`;

const ImageButton = styled.div`
  color: #2d3748;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin: 0;
  display: flex;
  height: 32px;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  border: 1px solid #cbd5e0;
  background: #fff;
  cursor: pointer;
`;

const Uploader = styled.div`
  cursor: pointer;

  .hover-wrapper {
    width: 100%;
    height: 100%;
  }

  h6 {
    color: #718096;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin: 4px;

    span {
      color: #ff3838;
    }
  }
`;

const ImageDefault = styled.img`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  text-align: center;
`;

const Fields = styled.div`
  flex-wrap: wrap;
  width: 500px;
  ${({ imgBox }) =>
    imgBox === "true" ? "display:grid; column-gap: 20px;" : "display:flex"};
  grid-template-columns: repeat(2, 1fr);

  > div {
  }

  @media only screen and (min-width: 992px) {
    > div {
      //width: 33.3%;
    }
  }

  .uploader__file_input_label {
  }

  .uploader__file_input_label > div {
    position: absolute;
  }
`;
const FieldGroup = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 10px;

  input {
    margin-bottom: 0 !important;
    margin-top: 5px !important;
  }

  input:read-only {
    background-color: #dad9d9;
  }

  em {
    font-size: 12px;
    font-weight: bold;
    color: #718096;
    margin-left: 3px;
  }

  label {
    margin-left: 0;

    > span,
    input {
      padding: 0;
      margin-top: 0px !important;
    }

    .MuiFormControlLabel-label {
      font-size: 14px;
      margin-left: 2px;
    }
  }
`;
export default OutsourcingForm;
