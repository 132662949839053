import styled from "@emotion/styled";
import React, { forwardRef, useEffect, useState } from "react";
import dayjs from "dayjs";
import { addCommas } from "../../bin/common";
import PrintSignComponent from "../PrintSignComponent";
import { TablePrintStyle } from "../../library/styled-components";

const ExpenseStatusPagePrint = forwardRef((props, ref) => {
  const [isImprests, setIsImprests] = useState([]);
  const [isDailyCashAmt, setIsDailyCashAmt] = useState({});

  const list = props.data.imprests;
  const amt = props.data.amt;
  const dailyCashAmt = props.data.dailyCashAmt;
  const site_seq = props.siteSeq;
  const imgNY = props.imgNY;
  useEffect(() => {
    setIsImprests(list.slice().reverse());
    setIsDailyCashAmt(dailyCashAmt.slice().reverse());
  }, [list, amt, dailyCashAmt]);
  return (
    <Container ref={ref}>
      <Header>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            gap: "7px",
          }}
        >
          <Title>
            {dayjs(props.data.date).format("YYYY년 MM월")}분 전도금 사용내역서
          </Title>

          <Text>
            <strong>현장명 </strong> <span>{props.data.site}</span>
          </Text>
        </div>

        <PrintSignComponent seq={site_seq}></PrintSignComponent>
      </Header>

      <TablePrintStyle className="page-break">
        <div className="table_wrapper">
          <table>
            <thead style={{ height: "unset" }}>
              <tr>
                <th rowSpan={2}>사용일자</th>
                <th rowSpan={2}>적요</th>
                <th rowSpan={2}>입금</th>
                <th colSpan={3}>지출</th>
                <th rowSpan={2}>현금잔액</th>
                <th rowSpan={2}>비고</th>
              </tr>
              <tr>
                <th>법인카드</th>
                <th>현금</th>
                <th className={"print_border"}>계좌이체</th>
              </tr>
            </thead>
            <tbody>
              {/* 리스트 출력 */}
              {!isDailyCashAmt ||
                (isImprests?.length > 0 &&
                  isImprests.map((e, i) => (
                    <tr key={i}>
                      <td className={""}>
                        {dayjs(e.imp_dt).format("YYYY년 M월 D일")}
                      </td>
                      <td className={"table-left"}>{e.class_seq2.class_nm}</td>
                      <td className={"table-right"}>
                        {e.imp_type === "I" ? e.imp_amt.toLocaleString() : ""}
                      </td>
                      <td className={"table-right"}>
                        {e.imp_type === "S" && e.method === "M0007D0001"
                          ? e.imp_amt.toLocaleString()
                          : "-"}
                      </td>
                      <td className={"table-right"}>
                        {e.imp_type === "S" && e.method === "M0007D0002"
                          ? e.imp_amt.toLocaleString()
                          : "-"}
                      </td>
                      <td className={"table-right"}>
                        {e.imp_type === "S" && e.method === "M0007D0003"
                          ? e.imp_amt.toLocaleString()
                          : "-"}
                      </td>
                      <td className={"table-right"}>
                        {addCommas(isDailyCashAmt[i].amt)}
                      </td>
                      <td style={{ textAlign: "center" }}>{e.desc}</td>
                    </tr>
                  )))}

              <tr>
                <th>월계</th>
                <th></th>
                <th className={"table-right"}>
                  {amt.monthly_input_amt.toLocaleString()}
                </th>
                <th colSpan={3} className={"table-right"}>
                  {amt.monthly_spend_amt.toLocaleString()}
                </th>
                <th className={"table-right"}>
                  {amt.monthly_balance.toLocaleString()}
                </th>
                <th></th>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th>누계</th>
                <th></th>
                <th className={"table-right"}>
                  {amt.total_input_amt.toLocaleString()}
                </th>
                <th colSpan={3} className={"table-right"}>
                  {amt.total_spend_amt.toLocaleString()}
                </th>
                <th></th>
                <th></th>
              </tr>
            </tfoot>
          </table>
        </div>
      </TablePrintStyle>

      {imgNY && (
        <TablePrintStyle style={{ marginTop: "20px" }}>
          <div
            className="table_wrapper"
            style={{ border: "none", borderRadius: "0px" }}
          >
            <Table>
              {list?.map(
                (e) =>
                  (e.imp_img1 ||
                    e.imp_img2 ||
                    e.imp_img3 ||
                    e.imp_img4 ||
                    e.imp_img5) && (
                    <tbody className="page-break" key={e.imp_seq}>
                      <tr className="text-line">
                        <td>{dayjs(e.imp_dt).format("YYYY년 M월 D일")}</td>
                        <td>{e.class_seq2.class_nm}</td>
                        <td>
                          {e.method === "M0007D0001"
                            ? "법인카드"
                            : e.method === "M0007D0002"
                            ? "현금"
                            : "계좌이체"}
                        </td>
                        <td>{e.imp_amt.toLocaleString()}</td>
                        <td>{e.desc}</td>
                      </tr>

                      <tr>
                        <td colSpan={5} style={{ borderBottom: "none" }}>
                          <div className="img-wrap">
                            {e.imp_img1 && (
                              <img src={e.imp_img1} alt="증빙이미지" />
                            )}
                            {e.imp_img2 && (
                              <img src={e.imp_img2} alt="증빙이미지" />
                            )}
                            {e.imp_img3 && (
                              <img src={e.imp_img3} alt="증빙이미지" />
                            )}
                            {e.imp_img4 && (
                              <img src={e.imp_img4} alt="증빙이미지" />
                            )}
                            {e.imp_img5 && (
                              <img src={e.imp_img5} alt="증빙이미지" />
                            )}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  )
              )}
            </Table>
          </div>
        </TablePrintStyle>
      )}
    </Container>
  );
});

const Container = styled.div`
  // border: 1px solid #E2E8F0;
  background: #fff;
  padding: 20px 24px;
  @page {
    size: A4 portrait !important;
    margin-top: 20px;
    margin-bottom: 25px;
  }
  .page-break {
    page-break-after: always;
  }
  @media print {
    table th {
      background-color: #edf2f7 !important;
      -webkit-print-color-adjust: exact;
    }

    .table-wrapper {
      break-after: page;
    }
    table {
      border-collapse: separate !important;
    }
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const Title = styled.div`
  color: #171923;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  //  margin-bottom: 6px;
`;

const HeaderGroup = styled.div`
  display: flex;
`;

const Badge = styled.div`
  display: flex;
  padding: 4px 6px;
  align-items: flex-start;
  border-radius: 6px;
  background: #f7fafc;

  &:not(:last-child) {
    margin-right: 4px;
  }
`;

const Label = styled.div`
  color: #718096;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: 8px;
  margin-right: 4px;
`;

const Text = styled.div`
  color: #171923;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: 8px;
`;

const TableContainer = styled.div``;

const TableWrapper = styled.div`
  overflow: hidden;
  border-radius: 6px;
  border: 1px solid #000;
  overflow-x: auto;
  box-sizing: border-box;
`;
const Table = styled.table`
  /* tr,
  tbody {
    page-break-inside: avoid;
  } */

  .img-wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    /* padding: 10px; */
    box-sizing: border-box;
    img {
      width: 100%;
      height: 100%;
    }
  }
  tr:last-child td {
    /* border-bottom: none; */
  }
  .text-line {
    td {
      border: 1px solid #000;
    }
    td:last-of-type {
      border-right: 1px solid #000 !important;
    }
  }
  td {
  }
`;

export default ExpenseStatusPagePrint;
