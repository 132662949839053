import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useForm } from "react-hook-form";
import ReactSelect from "react-select";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import axiosInstance from "../library/axios-index";
import { useMutation } from "@tanstack/react-query";
import { findCompanyID, findCompanyPassword } from "../library/axios-query";

// 박종관, 01023997233 ,seokwang
const FindIdPassword = () => {
  const { text } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm();
  const [tabSelect, setTabSelect] = useState(text);
  const finish = searchParams.get("finish");
  const [isPassword, setIsPassword] = useState({
    password: "",
    password_check: "",
  });
  const [value, setValue] = useState({
    corp_id: "",
    corp_phone: "",
    corp_certification: "",
    corp_name: "",
    phone_check: false,
  });
  // 아이디 찾기
  const { mutate: companyID, data: companyIDData } = useMutation({
    mutationKey: ["findCompanyID"],
    mutationFn: findCompanyID,
    onSuccess: (data) => {
      if (data.id !== "") {
        setSearchParams({ finish: "true" });
      }
    },
    onError: (error) => {
      console.error("Error fetching data:", error.message);
      //  alert(error.message);
    },
  });

  const { mutate: companyPassword } = useMutation({
    mutationKey: ["findCompanyPassword"],
    mutationFn: findCompanyPassword,
    onError: (error) => {
      console.error("error fetching data:", error.message);
      //  alert(error.message);
      navigate("/login");
    },
    onSuccess: (data) => {
      if (data.done === true) {
        alert("변경 완료 했습니다.");
        navigate("/login");
        return;
      } else {
        alert("비밀번호 변경에 실패했습니다.");
        window.location.reload();
        navigate("/login");
        return;
      }
    },
  });
  // 비밀번호 변경하기
  const onSubmitPassword = () => {
    const passwordRule =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,16}$/;
    const phoneCheck = passwordRule.test(isPassword.password);
    if (!phoneCheck) {
      alert("영문, 숫자, 특수문자를 포함한 8~16자리 입력해주세요.");
      return;
    }
    if (!isPassword.password_check || !isPassword.password) {
      alert("비밀번호를 입력해주세요.");
      return;
    }
    if (isPassword.password !== isPassword.password_check) {
      alert("비밀번호가 일치하지 않습니다. 다시 확인해주세요.");
      return;
    }
    const valuePassword = {
      phone_numb: value.corp_phone,
      cert_numb: value.corp_certification,
      id: value.corp_id,
      password: isPassword.password,
    };
    companyPassword(valuePassword);
  };
  const onSubmit = async (e) => {
    const phoneRule = /^(01[016789]{1})[0-9]{3,4}[0-9]{4}$/;
    const phoneCheck = phoneRule.test(value.corp_phone);

    if (text === "id") {
      //아이디 찾기
      if (!value.corp_name) {
        alert("이름을 입력해주세요");
        return;
      }
    } else {
      //비밀 번호 찾기
      if (!value.corp_id) {
        alert("아이디를 입력해주세요");
        return;
      }
    }
    if (!value.corp_phone) {
      alert("휴대전화 입력해주세요");
      return;
    }
    if (!phoneCheck) {
      alert("휴대전화 다시 확인해주세요");
      return;
    }
    if (!value.phone_check) {
      alert("휴대전화 인증해주세요");
      return;
    }

    if (!value.corp_certification) {
      alert("인증번호를 입력해주세요");
      return;
    }
    //인증 확인
    await axiosInstance
      .post("/sms/appSmsCert", {
        lang: "ko",
        phone_numb: value.corp_phone,
        cert_numb: value.corp_certification,
      })
      .then(({ data: { result: result } }) => {
        // 아이디 찾기

        if (result.done === true) {
          if (text === "id") {
            const valueID = {
              phone_numb: value.corp_phone,
              cert_numb: value.corp_certification,
              manager_nm: value.corp_name,
            };
            companyID(valueID);
          } else {
            // 비밀번호 변경
            setSearchParams({ finish: "true" });
          }
        } else {
          alert("인증번호가 일치하지 않습니다");
        }
      })
      .catch(({ response: { data } }) => {
        alert(data.message);
      });
  };

  const pageNavigate = (select) => {
    navigate(`/find-account/${select}?finish=false`);
  };

  useEffect(() => {
    setTabSelect(text);
    setValue({
      corp_id: "",
      corp_name: "",
      corp_phone: "",
      corp_certification: "",
      phone_check: false,
    });
  }, [text]);

  //뒤로가기
  useEffect(() => {
    const handlePopState = (e) => {
      navigate("/login");
    };
    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  const enterText = (e) => {
    const { value, name } = e.target;

    setValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === "corp_name" || name === "corp_phone" || name === "corp_id") {
      setValue((prevState) => ({
        ...prevState,
        phone_check: false,
      }));
    }
  };

  // 인중번호 전송
  const sendPhoneMessage = async (e) => {
    e.preventDefault();
    const phoneRule = /^(01[016789]{1})[0-9]{3,4}[0-9]{4}$/;
    const phoneCheck = phoneRule.test(value.corp_phone);
    if (value.phone_check === true) return;
    if (value.corp_phone === "") {
      alert("휴대폰 번호를 입력해주세요");
      return;
    }

    if (!phoneCheck) {
      alert("휴대전화 다시 확인해주세요.");
      return;
    }

    try {
      await axiosInstance.post("/sms/smsSend", {
        lang: "ko",
        phone_numb: value.corp_phone,
        platform: "WEB",
        check: false,
      });
      setValue((prevState) => ({
        ...prevState,
        phone_check: true,
        corp_phone: value.corp_phone,
      }));
      alert("인증번호가 발송 되었습니다.");
    } catch (e) {
      alert(e.response.data.message);
    }
  };
  const finishFalse = () => {
    return (
      <div>
        <TabItems>
          <span
            className={`${tabSelect === "id" && "action"}`}
            onClick={() => {
              pageNavigate("id");
            }}
          >
            아이디 찾기
          </span>
          <span
            className={`${tabSelect !== "id" && "action"}`}
            onClick={() => {
              pageNavigate("password");
            }}
          >
            비밀번호 찾기
          </span>
        </TabItems>
        <FindInfor>
          <span>회원정보로 등록된 휴대전화로 인증하기</span>
          <span>
            회원정보에 등록한 휴대전화 번호와 입력한 휴대전화 번호가 같아야,{" "}
            <br />
            인증번호를 받을 수 있습니다.
          </span>
        </FindInfor>
        <form onSubmit={handleSubmit(onSubmit)}>
          {tabSelect !== "id" ? (
            <div>
              <span>아이디</span>
              <TextBox>
                {" "}
                <Input
                  placeholder="아이디를 입력해주세요."
                  type="text"
                  {...register("corp_id")}
                  value={value.corp_id}
                  onChange={(e) => {
                    enterText(e);
                  }}
                />
              </TextBox>
            </div>
          ) : (
            <div>
              <span>이름</span>
              <TextBox>
                {" "}
                <Input
                  placeholder="이름을 입력해주세요."
                  type="text"
                  {...register("corp_name")}
                  value={value.corp_name}
                  onChange={(e) => {
                    enterText(e);
                  }}
                />
              </TextBox>
            </div>
          )}

          <div>
            <span>휴대전화</span>
            <TextBox
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Input
                placeholder="- 제외한 숫자만 입력해주세요."
                style={{ width: "calc(100% - 98px)" }}
                type="number"
                {...register("corp_phone")}
                value={value.corp_phone}
                onChange={(e) => {
                  enterText(e);
                }}
                onWheel={(event) => event.target.blur()}
              />
              <Button
                style={{
                  width: "90px",
                  background: "#3279F5",
                  color: "#fff",
                }}
              >
                <span style={{ fontSize: "16px" }} onClick={sendPhoneMessage}>
                  인증번호
                </span>
              </Button>
            </TextBox>
          </div>
          <div>
            <span>인증 번호</span>
            <TextBox style={{ position: "relative" }}>
              {" "}
              <Input
                placeholder="인증번호 6자리 숫자 입력"
                maxLength={6}
                type="number"
                {...register("corp_certification")}
                value={value.corp_certification}
                onWheel={(event) => event.target.blur()}
                onChange={(e) => {
                  enterText(e);
                }}
              />
              <span>인증번호를 입력해주세요.</span>
            </TextBox>
          </div>
          <Button
            type="submit"
            style={{
              height: "39px",
              marginTop: "50px",
              marginBottom: "20px",
              background: "#3279F5",
              color: "#fff",
            }}
          >
            <span>다음</span>
          </Button>
        </form>
        <Footer>
          <img
            src="/assets/icons/important-icon.svg"
            alt="인증에 어려움이 있으시면 customer@netsprint.net 으로 문의 메일 주세요."
          />
          <span>
            인증에 어려움이 있으시면 customer@netsprint.net 으로 문의 메일
            주세요.
          </span>
        </Footer>
      </div>
    );
  };

  const finishTrue = () => {
    return (
      <div>
        <FindTitleWrapper>
          <h2>{text === "id" ? "아이디 확인" : "비밀번호 변경"} </h2>
          <h3>
            {text === "id" ? (
              <>
                입력하신 정보와 일치하는 <br /> 아이디는 다음과 같습니다.
              </>
            ) : (
              <>
                고객님의 소중한 정보를 보호하기 위하여 <br />
                새로운 비밀번호로 변경 후 서비스를 이용해 주세요.
              </>
            )}
          </h3>
        </FindTitleWrapper>
        <FindContainer>
          {text === "id" ? (
            <>
              <span>
                {value.corp_name}님의 아이디는 <em>{companyIDData?.id}</em>
                입니다.
              </span>
              <Button
                style={{
                  width: "200px",
                  background: "#3279F5",
                  color: "#fff",
                  height: "40px",
                }}
                onClick={(event) => {
                  event.preventDefault();
                  navigate("/login");
                }}
              >
                <span>로그인 페이지 이동</span>
              </Button>
            </>
          ) : (
            <>
              <form
                onSubmit={handleSubmit(onSubmitPassword)}
                style={{
                  width: "100%",
                  padding: "0 20px",
                  boxSizing: "border-box",
                }}
              >
                <div>
                  <span>비밀번호</span>
                  <TextBox>
                    {" "}
                    <Input
                      placeholder="영문, 숫자, 특수문자를 포함한 8~16자리"
                      type="password"
                      {...register("password")}
                      value={isPassword.password}
                      onChange={(e) => {
                        const { value, name } = e.target;
                        setIsPassword((prevState) => ({
                          ...prevState,
                          [name]: value,
                        }));
                      }}
                    />
                  </TextBox>
                </div>

                <div>
                  <span>비밀번호 확인</span>
                  <TextBox style={{ position: "relative" }}>
                    {" "}
                    <Input
                      placeholder=""
                      type="password"
                      {...register("password_check")}
                      value={isPassword.password_check}
                      onChange={(e) => {
                        const { value, name } = e.target;
                        setIsPassword((prevState) => ({
                          ...prevState,
                          [name]: value,
                        }));
                      }}
                    />
                    {isPassword.password !== isPassword.password_check && (
                      <span style={{ color: "#ff3838", fontSize: "12px" }}>
                        비밀번호가 일치하지 않습니다.
                      </span>
                    )}
                  </TextBox>
                </div>
                <Button
                  type="submit"
                  style={{
                    width: "200px",
                    height: "39px",
                    margin: "60px auto 0",
                    background: "#3279F5",
                    color: "#fff",
                  }}
                >
                  <span>변경하기</span>
                </Button>
              </form>
            </>
          )}
        </FindContainer>
      </div>
    );
  };

  return (
    <Container>
      <Wrapper>
        <Header>
          <img
            src="/assets/images/ilsa-logo.png"
            style={{ width: "144px", height: "48px" }}
            alt="ilsa-logo"
          />
          <div>일과 사람</div>
        </Header>
        {finish === "true" ? finishTrue() : finishFalse()}
      </Wrapper>
    </Container>
  );
};
const Container = styled.div`
  background: #f7fafc;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Wrapper = styled.div`
  min-width: 540px;
  //max-width: 540px;
  //min-width: 370px;

  form {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }

  form > div {
    display: grid;
    grid-template-columns: 114px 1fr;
    align-items: center;

    > span {
      color: #000;
      font-size: 16px;
      font-weight: 500;
    }
  }

  @media only screen and (max-width: 768px) {
    width: calc(100vw - 50px);
    min-width: revert;
    //background: aqua;
    form > div {
      grid-template-columns: 90px 1fr;
    }

    form > div > span {
      font-size: 14px;
    }
  }
`;
const Header = styled.div`
  text-align: center;
  margin-bottom: 60px;
  @media only screen and (max-width: 768px) {
    margin-bottom: 30px;
    div {
      font-size: 14px;
      font-weight: 500;
    }

    img {
      width: 120px !important;
      height: auto !important;
    }
  }
`;
const TabItems = styled.div`
  grid-template-columns: repeat(2, 1fr);
  display: grid;
  border-bottom: 1px solid #3279f5;
  box-sizing: border-box;

  span {
    height: 62px;
    cursor: pointer;
    line-height: 62px;
    border-left: 1px solid #cbd5e0;
    border-top: 1px solid #cbd5e0;
    border-right: 1px solid #cbd5e0;
    box-sizing: border-box;
    text-align: center;
    color: #757575;
    font-size: 20px;
    font-weight: 700;
    background: #ffffff;
    @media only screen and (max-width: 768px) {
      font-size: 17px;
      line-height: 50px;
      height: 50px;
    }
  }

  span.action {
    background: #3279f5;
    border-left: 1px solid #3279f5;
    border-top: 1px solid #3279f5;
    border-right: 1px solid #3279f5;
    color: #ffffff;
  }
`;
const Select = styled(ReactSelect)`
  color: #2d3748;
  leading-trim: both;
  text-edge: cap;
  width: 90px;
  height: 45px;
  font-size: 14px;
  border-radius: 6px;
  background: #fff;
  //margin-top: 5px;

  > div {
    cursor: pointer;
    color: #2d3748;
    min-height: 45px;
    box-sizing: border-box;
  }
`;
const Button = styled.button`
  display: flex;
  width: 100%;
  @media only screen and (max-width: 768px) {
    font-size: 14px;
    &::placeholder {
      font-size: 14px;
    }

    span {
      font-size: 14px !important;
    }
  }
  padding: 0 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  cursor: pointer;
  border: none;
  text-decoration: none;
  box-shadow: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  span {
    font-size: 19px;
    font-weight: 500;
  }
`;
const Input = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-sizing: border-box;
  width: 100%;
  outline: none;
  padding: 12px;
  color: #171923;
  leading-trim: both;
  text-edge: cap;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: #fff;
  border: 1px solid #cbd5e0;
  border-radius: 6px;

  &::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }

  &::placeholder {
    color: #757575;
    font-weight: 500;
    font-size: 16px;
  }

  :focus {
  }

  @media only screen and (max-width: 768px) {
    font-size: 14px;

    &::placeholder {
      font-size: 14px;
    }
  }
`;
const FindInfor = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  margin: 25px 0;

  span {
    line-height: 24px;
    font-size: 15px;
    color: #2d3748;
    font-weight: 400;

    &:first-of-type {
      font-weight: 700;
    }
  }

  @media only screen and (max-width: 768px) {
    margin: 15px 0;
    row-gap: 0;
    span {
      line-height: 20px;
      font-size: 13px;
    }

    br {
      //display: none;
    }
  }
`;

const TextBox = styled.div`
  > span {
    position: absolute;
    left: 0;
    bottom: -25px;
    font-size: 12px;
    font-weight: 400;
  }
`;
const Footer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 7px;

  img {
    width: 14px;
    height: 14px;
  }

  span {
    font-size: 15px;
    font-weight: 500;
    color: #818893;
  }
`;

const FindTitleWrapper = styled.div`
  h2 {
    font-size: 40px;
    font-weight: 500;
    margin: 17px 0;
    text-align: center;
  }

  h3 {
    br {
      display: none;
    }

    text-align: center;
    font-weight: 300;
  }

  @media only screen and (max-width: 768px) {
    h2 {
      font-size: 20px;
      font-weight: 500;
      margin: 14px 0;
      text-align: center;
    }

    h3 {
      font-size: 14px;
      font-weight: 300;
    }
  }
`;
const FindContainer = styled.div`
  width: 100%;
  padding: 60px 0;
  box-sizing: border-box;
  border-radius: 12px;
  background: #ebf2fe;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 40px;
  margin-bottom: 40px;

  > span {
    font-size: 24px;
  }

  em {
    color: #3279f5;
    font-weight: 500;
    font-style: normal;
  }

  @media only screen and (max-width: 768px) {
    padding: 35px 0;
    > span {
      font-size: 19px;
    }

    button {
      margin: 30px auto 0 !important;
    }
  }
`;
export default FindIdPassword;
