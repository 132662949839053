import React, {
  forwardRef,
  useEffect,
  useState,
  useCallback,
  useRef,
} from "react";
import { TableStyle } from "../../library/styled-components";
import Modal from "../../compontents/Modal";
import moment from "moment";
import styled from "@emotion/styled";
import { InputBox } from "../../library/styled-components";
import { useMutation } from "@tanstack/react-query";
import { getMonthlyCommute } from "../../library/axios-query";
import EmptyContainer from "../../compontents/EmptyContainer";
import AttendanceMonthPrint from "../../compontents/print/AttendanceMonthPrint";
import DownLoadExcel from "../../library/handle-excel.js";

const AttendanceMonth = forwardRef((props, ref) => {
  const { date } = props;
  const componentRef = useRef();
  const [selectedDate, setSelectedDate] = useState(date);
  const [print, setPrint] = useState(false);
  const [days, setDays] = useState([]);
  const [startDay, setStartDay] = useState("");
  const [endDay, setEndDay] = useState("");

  // 출역 현황 - 개인별(월별) 출퇴근 정보 (WEB)
  const { mutate: getMonthlyCommuteMutate, data: getMonthlyCommuteData } =
    useMutation({
      mutationFn: getMonthlyCommute,
      mutationKey: ["getMonthlyCommute"],
      onSuccess: async (data) => {},
      onError: (error) => {
        throw error;
      },
    });

  useEffect(() => {
    setStartDay(moment(selectedDate).startOf("month").format("YYYY-MM-DD"));
    setEndDay(
      moment(selectedDate).add("1", "M").startOf("month").format("YYYY-MM-DD")
    );
    getMonthlyCommuteMutate({ workDt: selectedDate });
  }, [selectedDate]);

  useEffect(() => {
    const diffDay = moment(endDay).diff(moment(startDay), "days");
    for (let index = 1; index <= diffDay; index++) {
      setDays((prevState) => [
        ...prevState,
        {
          date: index,
          day: moment(`${selectedDate}-${index}`).format("dd"),
          allDate: moment(`${selectedDate}-${index}`).format("YYYY-MM-DD"),
        },
      ]);
    }
  }, [endDay, startDay]);

  // 프린트
  const handleOpenPrint = useCallback(() => {
    if (getMonthlyCommuteData?.length === 0) {
      alert("출력 가능한 내역이 없습니다");
      return;
    }
    setPrint(true);
  }, [getMonthlyCommuteData]);

  // 엑셀
  const handleExcel = () => {
    if (getMonthlyCommuteData?.length === 0) {
      alert("월별 출역 내역이 없습니다.");
      return;
    }

    DownLoadExcel({
      txt: "월별 출역 내역",
      date: moment(selectedDate).format("YYYY-MM"),
      id: "report-table2",
      type: 2,
    });
  };
  return (
    <div ref={ref}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "15px",
        }}
      >
        <InputBox style={{ width: "auto" }}>
          <div>
            <InputContainer
              name="start_dt"
              style={{ width: "230px", margin: "0px" }}
              type="month"
              value={selectedDate}
              onChange={(e) => {
                setDays([]);
                setSelectedDate(e.target.value);
              }}
            />
          </div>
        </InputBox>
        <ButtonGroup>
          <Button onClick={handleExcel}>
            <Icon src="/assets/icons/excel.svg" /> 엑셀파일
          </Button>
          <Button onClick={handleOpenPrint}>
            <Icon src="/assets/icons/printer.svg" />
            <span>출력하기</span>
          </Button>
        </ButtonGroup>
      </div>

      <TableStyle
        style={{
          padding: "0",
          margin: "0",
        }}
      >
        <TableWrapper>
          <table id={"report-table2"}>
            <thead>
              <tr>
                <th rowSpan={2} width={"140px"}>
                  성명
                </th>
                <th rowSpan={2}>현장</th>
                <th rowSpan={2} width={"40px"}>
                  근태
                </th>
                <th colSpan={days?.length}>
                  {`${startDay} ~ ${moment(selectedDate)
                    .endOf("month")
                    .format("YYYY-MM-DD")}`}
                </th>
              </tr>
              <tr>
                {days &&
                  days?.map((e, i) => (
                    <th
                      style={{ minWidth: "38px", width: "38px" }}
                      key={e.date}
                    >
                      {e.date}
                    </th>
                  ))}
              </tr>
            </thead>

            <tbody>
              {getMonthlyCommuteData && getMonthlyCommuteData.length !== 0 ? (
                getMonthlyCommuteData?.map((e, i) => (
                  <React.Fragment key={i}>
                    <tr>
                      <td
                        rowSpan={2}
                        style={{ width: "140px", minWidth: "140px" }}
                        className={` ${
                          getMonthlyCommuteData.length === i + 1 &&
                          "none-border"
                        } spaces`}
                      >
                        {e.userNm}
                      </td>
                      <td
                        rowSpan={2}
                        className={` ${
                          getMonthlyCommuteData.length === i + 1 &&
                          "none-border"
                        } spaces border table-left`}
                      >
                        {e.siteNm}
                      </td>
                      <td
                        style={{
                          width: "40px",
                          minWidth: "40px",
                          borderBottomStyle: "dotted",
                        }}
                      >
                        출근
                      </td>

                      {days &&
                        days.map((item) => (
                          <td
                            key={item.date}
                            className={`value ${
                              e.commuteTime.find(
                                (info) => info.workDt === item.allDate
                              )?.startDt &&
                              e.commuteTime.find(
                                (info) => info.workDt === item.allDate
                              )?.endDt &&
                              e.commuteTime.find(
                                (info) => info.workDt === item.allDate
                              )?.salaryType !== "D" &&
                              (!e.commuteTime.find(
                                (info) => info.workDt === item.allDate
                              )?.actualStartDt ||
                                !e.commuteTime.find(
                                  (info) => info.workDt === item.allDate
                                )?.actualEndDt)
                                ? "#ff3838"
                                : ""
                            }`}
                          >
                            {e.commuteTime.find(
                              (info) => info.workDt === item.allDate
                            )?.startDt &&
                              moment(
                                e.commuteTime.find(
                                  (info) => info.workDt === item.allDate
                                )?.startDt
                              ).format("HH:mm")}
                          </td>
                        ))}
                    </tr>
                    <tr>
                      <td
                        style={{
                          width: "40px",
                          minWidth: "40px",
                        }}
                      >
                        퇴근
                      </td>
                      {days &&
                        days.map((item) => (
                          <td
                            key={item.date}
                            className={`value ${
                              e.commuteTime.find(
                                (info) => info.workDt === item.allDate
                              )?.startDt &&
                              e.commuteTime.find(
                                (info) => info.workDt === item.allDate
                              )?.endDt &&
                              e.commuteTime.find(
                                (info) => info.workDt === item.allDate
                              )?.salaryType !== "D" &&
                              (!e.commuteTime.find(
                                (info) => info.workDt === item.allDate
                              )?.actualStartDt ||
                                !e.commuteTime.find(
                                  (info) => info.workDt === item.allDate
                                )?.actualEndDt)
                                ? "#ff3838"
                                : e.commuteTime
                                    .find(
                                      (info) => info.workDt === item.allDate
                                    )
                                    ?.endDt?.split(" ")[0] > item.allDate
                                ? "blue"
                                : ""
                            }`}
                          >
                            {e.commuteTime.find(
                              (info) => info.workDt === item.allDate
                            )?.endDt &&
                              moment(
                                e.commuteTime.find(
                                  (info) => info.workDt === item.allDate
                                )?.endDt
                              ).format("HH:mm")}
                          </td>
                        ))}
                    </tr>
                  </React.Fragment>
                ))
              ) : (
                <tr>
                  <td colSpan={50}>
                    <EmptyContainer
                      selected={false}
                      falseText={`월별 출역 내역이`}
                      style={{ height: "500px", background: "#fff" }}
                    ></EmptyContainer>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </TableWrapper>
      </TableStyle>

      <Infor>
        * <em className="red">빨간색</em> : 월급여/본사협의 자동출근(실제
        출/퇴근 내역 없음),
        <em className="blue"> 파란색</em> : 철야근무
      </Infor>
      <Modal
        opend={print}
        closeModal={() => setPrint(false)}
        header={"출력 미리보기"}
        okText={"출력"}
        handleOk={() => {
          //   handlePrint();
        }}
        overlayStyle={{ display: "none" }}
        widthCheck={"70%"}
      >
        <AttendanceMonthPrint
          ref={componentRef}
          startDay={startDay}
          selectedDate={selectedDate}
          day={`조회기간 : ${moment(selectedDate).format("YYYY년 M월")}`}
          getMonthlyCommuteData={getMonthlyCommuteData}
          setPrint={setPrint}
          days={days}
        ></AttendanceMonthPrint>
      </Modal>
    </div>
  );
});
const Infor = styled.span`
  font-size: 13px;
  font-weight: 300;
  > em {
    font-weight: 500;
  }
`;
const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
`;
const Icon = styled.img`
  width: 16px;
`;
const Button = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
  min-height: 32px;
  padding: 0 9px;
  justify-content: center;
  align-items: center;
  gap: 9px;
  border-radius: 6px;
  border: 1.125px solid #e2e8f0;
  background: #fff;

  color: #4a5568;
  leading-trim: both;
  text-edge: cap;
  font-size: 14px;
  cursor: pointer;
`;

const TableWrapper = styled.div`
  th,
  td {
    text-align: center;
  }

  .value {
    font-size: 11px;
    width: 38px;
    min-width: 38px;

    &.red {
      color: #ff3838;
    }
    &.blue {
      color: #3273eb;
    }
  }
`;

const InputContainer = styled.input`
  &::-webkit-inner-spin-button,
  &::-webkit-clear-button {
    display: none;
  }
`;

export default AttendanceMonth;
