import styled from "@emotion/styled";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Header } from "../../compontents/Header";
import ReactSelect from "react-select";
import Pagination from "react-js-pagination";
import Modal from "../../compontents/Modal";
import Calendar from "../../compontents/Calendar";
import { PagingFooter } from "../../compontents/PagingFooter";
import LaborAttendancePagePrint from "../../compontents/print/LaborAttendancePrint";
import { useReactToPrint } from "react-to-print";
import axiosInstance from "../../library/axios-index";
import { HOST_URL } from "../../bin/env";
import dayjs from "dayjs";
import SideModal from "../../compontents/SideModal";
import DownLoadExcel from "../../library/handle-excel";
import SiteContainers from "../../compontents/SiteContainer";
import AxiosNavigate from "../../library/axios-navigate";
import { useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import {
  BlockScrolling,
  checkRole,
  getAllDatesInMonth,
} from "../../bin/common";
import { RoleType } from "../../bin/enum";
import ImageEnlargedModal from "../../compontents/ImageEnlagedModal";
import Loading from "../../compontents/Loading";
import PagesList from "../../compontents/PagesList";
import SearchBox from "../../compontents/Search";
import ButtonComponent from "../../compontents/Button";
import { FormProvider, useForm } from "react-hook-form";
import NewAttendanceForm from "./new-attendance-form";
import moment from "moment";
import AttendanceCalendar from "../../compontents/AttendanceCalendar";
import { useMutation } from "@tanstack/react-query";
import { getLaborerAttendance } from "../../library/admin-react-query";
import EmptyContainer from "../../compontents/EmptyContainer";
import { deleteLaborAttendanceClosing } from "../../library/axios-query";
import NaverMapLocation from "../../compontents/NaverMapLocation";
import { WidthStyle } from "../../library/styled-components.js";

const options = [
  { value: 20, label: "20" },
  { value: 30, label: "30" },
  { value: 50, label: "50" },
  { value: 100, label: "100" },
];

const manDayOptions = [
  { value: 0, label: "0" },
  { value: 0.1, label: "0.1" },
  { value: 0.2, label: "0.2" },
  { value: 0.3, label: "0.3" },
  { value: 0.4, label: "0.4" },
  { value: 0.5, label: "0.5" },
  { value: 0.6, label: "0.6" },
  { value: 0.7, label: "0.7" },
  { value: 0.8, label: "0.8" },
  { value: 0.9, label: "0.9" },
  { value: 1.0, label: "1.0" },
];

function LaborAttendance() {
  const newDate = new Date();
  const nowDate = moment(newDate).format("YYYY-MM-DD");

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const location = useLocation();
  const [cookies] = useCookies(["role"]);

  const formRef = useRef();
  const methods = useForm();

  const [loading, setLoading] = useState(false);
  const [opendExport, setOpendExport] = useState(false);
  const [opendSideModal, setOpendSideModal] = useState(false);
  const [opendDetailLabor, setOpendDetailLabor] = useState(false);
  const [openedManage, setOpenedManage] = useState(false);

  const [sites, setSites] = useState([]);
  const [siteList, setSiteList] = useState([]);
  const [selectedDate, setSelectedDate] = useState(
    dayjs().format("YYYY-MM-DD")
  );

  const [calendarSelectedDate, setCalendarSelectedDate] = useState(null);
  const [selectedAllDates, setSelectedAllDates] = useState([]);
  const [selectedSite, setSelectedSite] = useState(0);
  const [selectedSiteNm, setSelectedSiteNm] = useState(""); // 현장명
  const [searchName, setSearchName] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(options[0].value);
  const [totalCount, setTotalCount] = useState(0);
  const [siteUserCount, setSiteUserCount] = useState(0);
  const [calendarData, setCalendarData] = useState([]);

  const [report, setReport] = useState(undefined);
  const [attendanceList, setAttendanceList] = useState([]);
  const [detailLabor, setDetailLabor] = useState(null);

  const [opendImage, setOpendImage] = useState(false);
  const [opendLocation, setOpendLocation] = useState(false);
  const [opendImageIndex, setOpendImageIndex] = useState(0);
  const [imageArr, setImageArr] = useState([]);
  const [locationMode, setLocationMode] = useState("start");

  const [sideLaborList, setSideLaborList] = useState([]);
  const [sideSearchList, setSideSearchList] = useState([]); // 검색결과
  const [sideData, setSideData] = useState({
    user_seq: 0,
    site_seq: 0,
    user_site_seq: 0,
    work_dt: "",
    name: "",
    id_num: "",
    man_day_am: "0",
    man_day_pm: "0",
    man_day_night: "0",
    reason: "",
    rec_seq: "", // 등록할때만 필요
  });

  const formatDateBetween = moment(nowDate).isBetween(
    moment(newDate).subtract(1, "months").endOf("month").format("YYYY-MM-DD"),
    moment(newDate).format("YYYY-MM-15")
  );
  const selectedDateBetween = moment(selectedDate).isBetween(
    moment(newDate).subtract(2, "months").endOf("month").format("YYYY-MM-DD"),
    moment(newDate).startOf("month").format("YYYY-MM-DD")
  );

  BlockScrolling(opendExport);
  BlockScrolling(opendSideModal);
  BlockScrolling(opendDetailLabor);

  const { mutate: getLaborerAttendanceItem, data: getLaborerAttendanceData } =
    useMutation({
      mutationFn: getLaborerAttendance,
      mutationKey: ["getLaborerAttendance"],
      onSuccess: (data) => {},
      onError: (error) => {
        throw error;
      },
    });
  const { mutate: deleteLaborAttendanceClosingItem } = useMutation({
    mutationFn: deleteLaborAttendanceClosing,
    mutationKey: ["deleteLaborAttendanceClosing"],
    onSuccess: async (data) => {
      if (data.done) {
        await loadLaborAttendance();
      }
    },
    onError: (error) => {
      throw error;
    },
  });

  const handleAllSites = useCallback(async () => {
    const token = localStorage.getItem("admin_token");
    const {
      data: { result },
    } = await axiosInstance.post(`${HOST_URL}/admin/searchSite`, {
      lang: "ko",
      uuid: "string",
      token,
      search_txt: "",
    });

    setSites(result);
    setSiteList(result);
    setReport(undefined);
    setCalendarData([]);
    setSelectedSite(0);
    setAttendanceList([]);
  }, []);

  // 현장 검색
  const handleSearchSite = (value) => {
    setSearchName("");
    const list = siteList.filter((e) =>
      e.site_nm
        .toLowerCase()
        .replaceAll(" ", "")
        .includes(value.toLowerCase().replaceAll(" ", ""))
    );
    setSites(list);
  };

  const handleSelectedSite = (e) => {
    setSearchName("");
    setSelectedSite(e);
    setSelectedSiteNm(sites.filter((site) => site.site_seq === e)[0].site_nm);
  };

  // 리스트 로드
  const loadLaborAttendance = async () => {
    const token = localStorage.getItem("admin_token");
    const request = {
      lang: "ko",
      uuid: "stirng",
      token,
      site_seq: selectedSite,
      work_dt: selectedDate,
      search_txt: searchName.replaceAll(" ", ""),
      page: page,
      limit: limit,
    };

    const {
      data: { result },
    } = await axiosInstance.post(
      `${HOST_URL}/admin/laborAttendanceList`,
      request
    );
    setCalendarData(result.attendanceDate);

    setTotalCount(result.totalCount);
    setSiteUserCount(result.siteUserCount);

    if (!Object.keys(result.attendances).length) {
      if (searchName !== "") {
        alert("검색결과가 없습니다");
        setAttendanceList([]);
        return;
      } else {
        setReport(result);
        setAttendanceList([]);
        return;
      }
    }

    setReport(result);
    setAttendanceList(result.attendances);
  };

  const loadSideList = async () => {
    const token = localStorage.getItem("admin_token");

    const {
      data: {
        result: { laborEmployees, totalCount },
      },
    } = await axiosInstance.post(`${HOST_URL}/admin/laborSearchList`, {
      lang: "ko",
      uuid: "string",
      token,
      site_seq: selectedSite,
    });

    setSideLaborList(laborEmployees);
    setSideSearchList(laborEmployees);
  };

  const handleDetailLabor = (userSiteSeq) => {
    const detailLabor = attendanceList?.find(
      (e) => e.user_site_seq == userSiteSeq
    );
    const imgArr = [];
    imgArr.push({ src: detailLabor.profile_photo });
    imgArr.push({ src: detailLabor.photo_start });
    imgArr.push({ src: detailLabor.photo_end });
    setImageArr(imgArr);
    setDetailLabor(detailLabor);
  };

  const handleImageIndex = (direction) => {
    if (direction === "right") {
      if (opendImageIndex < imageArr?.length - 1) {
        setOpendImageIndex((opendImageIndex) => opendImageIndex + 1);
      } else {
        setOpendImageIndex(0);
      }
    } else {
      if (opendImageIndex > 0) {
        setOpendImageIndex((opendImageIndex) => opendImageIndex - 1);
      } else {
        setOpendImageIndex(imageArr?.length - 1);
      }
    }
  };

  // 엑셀 다운로드
  const handleExcel = () => {
    if (attendanceList < 1) {
      alert("출역일보가 없습니다.");
      return;
    }

    DownLoadExcel({
      txt: "출역일보",
      date: dayjs(selectedDate).format("YYYY-MM-DD"),
      site: selectedSiteNm,
      type: 1,
    });
  };

  const onClickLaborAttendanceClosingButton = async () => {
    let nowDate = new Date();
    const formatDate = moment.utc(nowDate).format("YYYY-MM");
    const formatSelectedDate = moment.utc(selectedDate).format("YYYY-MM");
    if (report === undefined) {
      alert("현장을 선택해 주세요.");
      return;
    }

    if (report?.is_editable === "N") {
      alert(
        `[${dayjs(selectedDate).format("YYYY년 M월")}] 마감 완료 되었습니다.`
      );
      return;
    }

    if (formatDate === formatSelectedDate) {
      alert("당월 본사마감은 차월 1일부터 가능합니다.");
      return;
    }

    const answer = window.confirm(
      `[${dayjs(selectedDate).format("YYYY년 M월")}] 마감을 하시겠습니까?\n` +
        "본사마감 이후에는 출역 입력/수정이 되지 않습니다.\n" +
        "진행하시겠습니까?"
    );
    if (answer) {
      //some code
      setLoading(true);
      await handleLaborAttendanceClosing();
    }
  };
  const handleLaborAttendanceClosingCancel = (report) => {
    const answer = window.confirm(
      `[${dayjs(selectedDate).format("YYYY년 M월")}] 마감을 최소하시겠습니까?`
    );
    if (answer) {
      const items = {
        site_seq: report.site_seq,
        closing_dt: selectedDate,
      };
      deleteLaborAttendanceClosingItem(items);
    }
  };
  const handleLaborAttendanceClosing = async () => {
    const token = localStorage.getItem("admin_token");
    const request = {
      site_seq: report.site_seq,
      closing_dt: dayjs(selectedDate).format("YYYY-MM-DD"),
    };

    try {
      const {
        data: { result },
      } = await axiosInstance.post(
        `${HOST_URL}/admin/laborAttendanceClosing`,
        request
      );

      if (result.done === true) {
        alert("마감처리 되었습니다.");
        await loadLaborAttendance();
        setTimeout(async () => {
          setLoading(false);
        }, 400);
      }
    } catch (e) {
      alert("마감처리에 실패하였습니다. ", e);
      setLoading(false);
    }
  };

  const handleSideModal = () => {
    if (selectedDate === "" || selectedDate === undefined) {
      alert("날짜를 선택해주세요");
      return;
    }

    if (selectedSite === 0) {
      alert("현장을 선택해주세요");
      return;
    }

    setOpendSideModal(true);
    setSideSearchList(sideLaborList);
    setSideData({
      ...sideData,
      user_seq: 0,
      user_site_seq: 0,
      name: "",
      id_num: "",
      man_day_am: "0",
      man_day_pm: "0",
      man_day_night: "0",
      reason: "",
      rec_seq: "", // 등록할때만 필요
    }); // 초기화

    // getAllDatesInMonth(selectedDate);

    setSelectedAllDates(getAllDatesInMonth(selectedDate));
  };

  const handleOpenPrintModal = useCallback(() => {
    if (attendanceList.length < 1) {
      alert("출역일보가 없습니다.");
      return;
    }
    setOpendExport(true);
  }, [attendanceList]);

  useEffect(() => {
    if (selectedSite === 0) return;
    (async () => {
      await loadLaborAttendance();
    })();
  }, [selectedSite, selectedDate, page, limit]);

  useEffect(() => {
    (async () => {
      await loadSideList();
    })();
  }, [attendanceList]);

  useEffect(() => {
    (async () => {
      await handleAllSites();
    })();
  }, []);
  const today = new Date();
  const tileClassName = ({ view, date }) => {
    if (view === "month" && date > today) {
      // 조건부 로그, 필요할 때만 출력
      return "future-date";
    }
    return null;
  };
  const handleDayClick = (e) => {
    const isToday = today.setHours(0, 0, 0, 0);
    const isDate = new Date(e).getTime();
    if (isDate > isToday) {
      return;
    }
    return;
  };

  const formFinish = async () => {
    setOpendSideModal(false);
    setOpenedManage(false);
    await loadLaborAttendance();
  };

  const handleAttendanceList = (e) => {
    const dataItems = {
      user_seq: sideData?.user_seq,
      site_seq: selectedSite,
      selectedDate: e,
    };

    getLaborerAttendanceItem(dataItems);
  };

  const handleAttendanceInfo = (data) => {
    setCalendarSelectedDate(data);
  };

  const selectedWorkdt = (selected) => {
    const work_Date = getLaborerAttendanceData?.attendanceData?.filter(
      (e, i) => {
        return e?.work_dt === selected && e.user_seq === sideData?.user_seq;
      }
    );
    return work_Date;
  };

  return (
    <AxiosNavigate>
      <div>
        <Header>출역 일보</Header>
        <Content>
          <Info>
            <CalendarContainer style={{ paddingLeft: "16px" }}>
              <Calendar
                onChangeDate={(value) => {
                  setSelectedDate(dayjs(value).format("YYYY-MM-DD")) ||
                    setSearchName("") ||
                    setPage(1);
                }}
                data={calendarData}
                tileClassName={tileClassName}
                handleDayClick={handleDayClick}
              />
            </CalendarContainer>
            <SiteContainers
              data={{
                sites,
                handleAllSites,
                handleSearchSite,
                handleSelectedSite,
              }}
            />
          </Info>
          {loading ? (
            <Loading></Loading>
          ) : selectedSite !== 0 ? (
            <Board>
              <div
                style={{
                  padding: "0 32px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <BoardHeader>
                  <h2>{selectedSiteNm === 0 ? "" : selectedSiteNm}</h2>
                  <h5>{dayjs(selectedDate).format("YYYY-MM-DD")}</h5>
                </BoardHeader>

                <div>
                  <Button
                    style={
                      report === undefined
                        ? { backgroundColor: "#3279F5", color: "#fff" }
                        : report?.is_editable === "Y"
                        ? {
                            backgroundColor: "#3279F5",
                            color: "#fff",
                          }
                        : formatDateBetween && selectedDateBetween
                        ? {
                            backgroundColor: "#f9df76",
                            color: "#4a5568",
                          }
                        : {
                            backgroundColor: "red",
                            color: "#fff",
                          }
                    }
                    onClick={async () => {
                      if (
                        checkRole(
                          location.pathname,
                          cookies.role,
                          RoleType.WRITE
                        )
                      ) {
                        if (
                          formatDateBetween &&
                          selectedDateBetween &&
                          report?.is_editable === "N"
                        ) {
                          handleLaborAttendanceClosingCancel(report);
                        } else {
                          await onClickLaborAttendanceClosingButton();
                        }
                      }
                    }}
                  >
                    {report === undefined
                      ? "본사마감"
                      : report?.is_editable === "Y"
                      ? "본사마감"
                      : formatDateBetween && selectedDateBetween
                      ? "마감취소"
                      : "마감완료"}
                  </Button>
                </div>
              </div>
              <span
                style={{
                  textAlign: "right",
                  display: "block",
                  padding: "0 32px",
                  color: "#ff3838",
                  fontWeight: "600",
                  fontSize: "13px",
                  marginBottom: "8px",
                  marginTop: "-12px",
                }}
              >
                당월 출역 일보는 차월 15일 0시에 자동 마감됩니다.
              </span>
              <Panel>
                <div>
                  <SearchBox
                    placeholder="직원 검색"
                    style={{ backgroundColor: "#fff" }}
                    value={searchName}
                    onKeyDown={async (e) => {
                      if (e.isComposing || e.keyCode === 229) return;
                      if (
                        e.key === "Enter" &&
                        e.nativeEvent.isComposing === false
                      ) {
                        if (
                          e.target.value.length > 0 &&
                          e.target.value.trim().length === 0
                        ) {
                          alert("직원을 입력해 주세요");
                          setSearchName(e.target.value.trim());
                          return;
                        }
                        setSearchName(e.target.value.trim());
                        await loadLaborAttendance();
                      }
                    }}
                    onChange={(e) => {
                      setSearchName(e.target.value);
                    }}
                  ></SearchBox>
                </div>
                <div>
                  <ButtonGroup>
                    <Button onClick={handleExcel}>
                      <Icon src="/assets/icons/excel.svg" /> 엑셀파일
                    </Button>
                    <Button onClick={handleOpenPrintModal}>
                      <Icon src="/assets/icons/printer.svg" /> 출력하기
                    </Button>
                    <GreenButton
                      onClick={() => {
                        if (
                          checkRole(
                            location.pathname,
                            cookies.role,
                            RoleType.WRITE
                          )
                        ) {
                          if (report?.is_editable === "N") {
                            alert("본사 마감이 완료되어 입력 할 수 없습니다");
                            return;
                          }
                          handleSideModal();
                        }
                      }}
                    >
                      {"출역 관리"}
                    </GreenButton>
                  </ButtonGroup>
                </div>
              </Panel>
              <Panel
                style={{
                  marginTop: "20px",
                  marginRight: "10px",
                  justifyContent: "flex-end",
                  color: "#4a5568",
                  fontSize: "15px",
                }}
              >
                출역인원 : {totalCount} / 전체인원 : {siteUserCount}
              </Panel>
              <TableContainer>
                <TableWrapper>
                  <Table id={"report-table"}>
                    <thead>
                      <tr>
                        <th rowSpan={2}>구분</th>
                        <th rowSpan={2}>이름</th>
                        <th rowSpan={2}>주민등록번호</th>
                        <th rowSpan={2}>직종</th>
                        <th rowSpan={2}>소속</th>
                        <th rowSpan={2}>출근시간</th>
                        <th rowSpan={2}>퇴근시간</th>
                        <th rowSpan={2}>
                          실제 <br />
                          출근시간
                        </th>
                        <th rowSpan={2}>
                          실제 <br />
                          퇴근시간
                        </th>
                        <th colSpan={4}>출역공수</th>
                        <th rowSpan={2}>단가</th>
                        <th rowSpan={2}>금액</th>
                        <th rowSpan={2}>수동입력 사유</th>
                      </tr>
                      <tr>
                        <th>오전</th>
                        <th>오후</th>
                        <th>야간</th>
                        <th style={{ borderRight: "1px solid #cbd5e0" }}>
                          합계
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {attendanceList && attendanceList.length === 0 ? (
                        <tr style={{ height: "500px" }}>
                          <td colSpan={16}>
                            <EmptyContainer
                              falseText={"출역 일보가"}
                              style={{ background: "#fff" }}
                            ></EmptyContainer>
                          </td>
                        </tr>
                      ) : (
                        attendanceList?.map((e, i) => (
                          <tr key={i}>
                            <td>
                              <div style={{ padding: "8px 0" }}>
                                {i + limit * (page - 1) + 1}
                              </div>
                            </td>
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                {e.user_nm}
                                <MoreInfoButton
                                  className={"remove"}
                                  onClick={() => {
                                    setOpendDetailLabor(true);
                                    handleDetailLabor(e.user_site_seq);
                                    //   handleAttendanceList(e);
                                    setSideData({
                                      user_seq: e.user_seq,
                                      user_site_seq: e.user_site_seq,
                                      name: e.name,
                                      id_num: e.id_num,
                                      man_day_am: e.man_day_am,
                                      man_day_pm: e.man_day_pm,
                                      man_day_night: e.man_day_night,
                                      reason: e.reason,
                                      rec_seq: e.rec_seq, // 등록할때만 필요
                                    }); // 초기화
                                  }}
                                  style={{
                                    marginLeft: "8px",
                                    boxSizing: "border-box",
                                  }}
                                >
                                  상세보기
                                </MoreInfoButton>
                              </div>
                            </td>
                            <td style={{ textAlign: "center" }}>{e.id_num}</td>
                            <td style={{ textAlign: "center" }}>
                              {e.job_position}
                            </td>
                            <td>{e.cmpny_nm}</td>
                            <td
                              style={{
                                textAlign: "center",
                                color:
                                  e.start_dt &&
                                  e.end_dt &&
                                  e.salary_type !== "일" &&
                                  (!e.actual_start_dt || !e.actual_end_dt)
                                    ? "#ff3838"
                                    : "#000",
                              }}
                            >
                              {e.start_dt
                                ? dayjs(e.start_dt)
                                    .add(-9, "hour")
                                    .format("HH:mm")
                                : ""}
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                                color:
                                  e.start_dt &&
                                  e.end_dt &&
                                  e?.actual_end_dt?.split("T")[0] !==
                                    e?.actual_start_dt?.split("T")[0]
                                    ? e?.actual_end_dt && "#3279F5"
                                    : e.salary_type !== "일" &&
                                      (!e.actual_start_dt || !e.actual_end_dt)
                                    ? "#ff3838"
                                    : "#000",
                              }}
                            >
                              {e?.actual_end_dt &&
                              e?.actual_end_dt?.split("T")[0] !==
                                e?.actual_start_dt?.split("T")[0] ? (
                                <>
                                  {e?.actual_end_dt?.split("T")[0]} <br />
                                </>
                              ) : (
                                ""
                              )}
                              {e.end_dt
                                ? dayjs(e.end_dt)
                                    .add(-9, "hour")
                                    .format("HH:mm")
                                : ""}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {e.actual_start_dt
                                ? dayjs(e.actual_start_dt)
                                    .add(-9, "hour")
                                    .format("HH:mm")
                                : ""}
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                                color:
                                  e.actual_start_dt && e.actual_end_dt
                                    ? e.actual_end_dt?.split("T")[0] !==
                                        e.actual_start_dt?.split("T")[0] &&
                                      "#3279F5"
                                    : "#000",
                              }}
                            >
                              {e?.actual_end_dt &&
                              e?.actual_start_dt &&
                              e?.actual_end_dt?.split("T")[0] !==
                                e.actual_start_dt?.split("T")[0] ? (
                                <>
                                  {e?.actual_end_dt?.split("T")[0]} <br />
                                </>
                              ) : (
                                ""
                              )}
                              {e?.actual_end_dt
                                ? dayjs(e.actual_end_dt)
                                    .add(-9, "hour")
                                    .format("HH:mm")
                                : ""}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {e.man_day_am}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {e.man_day_pm}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {e.man_day_night}
                            </td>
                            <td style={{ textAlign: "center" }}>{e.man_day}</td>
                            <td className={"table-right"}>
                              {e.day_amt.toLocaleString()}
                            </td>
                            <td className={"table-right"}>
                              {e.salary_amt.toLocaleString()}
                            </td>
                            <WidthStyle
                              width={"250px"}
                              space={true}
                              // style={{
                              //   width: "250px",
                              //   minWidth: "250px",
                              //   whiteSpace: "break-spaces",
                              // }}
                            >
                              {e.reason}
                            </WidthStyle>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                </TableWrapper>
              </TableContainer>
              {/* 페이징 */}
              <PagingFooter
                style={{ position: "relative", padding: "10px 0 50px" }}
              >
                {/*<PageGroup>*/}
                <Pagination
                  prevPageText={<Icon src="/assets/icons/chevron-left.svg" />}
                  nextPageText={<Icon src="/assets/icons/chevron-right.svg" />}
                  activePage={page}
                  itemsCountPerPage={limit}
                  totalItemsCount={totalCount === 0 ? 1 : totalCount}
                  pageRangeDisplayed={5}
                  hideFirstLastPages={true}
                  onChange={(e) => {
                    setPage(e);
                  }}
                />
                {/*</PageGroup>*/}
                <PagesList
                  options={options}
                  onChange={(e) => {
                    setLimit(e.value);
                    setPage(1);
                  }}
                ></PagesList>
              </PagingFooter>
            </Board>
          ) : (
            <EmptyContainer selected={true} falseText={`출역 일보가`}>
              {" "}
            </EmptyContainer>
          )}
        </Content>

        {/* 프린트 미리보기 */}
        <Modal
          opend={opendExport}
          closeModal={() => setOpendExport(false)}
          header={"출력 미리보기"}
          okText={"출력"}
          handleOk={() => {
            handlePrint();
          }}
          widthCheck={"70%"}
        >
          <LaborAttendancePagePrint
            ref={componentRef}
            data={report}
            totalCount={totalCount}
            siteUserCount={siteUserCount}
          />
        </Modal>
        {/* 수동입력 모달 sideModal */}
        <SideModal
          opend={opendSideModal}
          closeModal={() => setOpendSideModal(false)}
          header={"출역인원 검색"}
        >
          {
            <div>
              <Search style={{ marginBottom: "20px" }}>
                <SearchIcon src="/assets/icons/search-sm.svg" />
                <SearchInput
                  placeholder="직원 검색"
                  onChange={(v) => {
                    if (v.target.value !== undefined) {
                      const searchMap = sideLaborList.filter((e) =>
                        e.user_nm.includes(v.target.value)
                      );
                      const searchName = searchMap.map((e) => ({
                        user_nm: e.user_nm,
                        id_num: e.id_num,
                        user_seq: e.user_seq,
                        user_site_seq: e.user_site_seq,
                        salary_type: e.salary_type,
                      }));
                      setSideSearchList(searchName);
                    }
                  }}
                />
              </Search>
              {!sideSearchList.length ? (
                <EmptyLaborCard>근로자가 존재하지 않습니다</EmptyLaborCard>
              ) : (
                sideSearchList.map((e, i) => (
                  <LaborCard
                    key={i}
                    onClick={(event) => {
                      if (e?.salary_type !== "D") {
                        alert(
                          "월급/본사협의(월급) 대상자는 수정할 수 없습니다."
                        );
                        return;
                      }
                      setOpenedManage(true);
                      setSideData({
                        ...sideData,
                        user_seq: e.user_seq,
                        name: e.user_nm,
                        id_num: e.id_num,
                        user_site_seq: e.user_site_seq,
                        salary_type: e.salary_type,
                        rec_seq: "-1",
                      });
                    }}
                  >
                    <LaborCardContent>
                      <h5>
                        {e.user_nm} / {e.id_num}{" "}
                        {e.leave_dt !== null && (
                          <em style={{ color: "#ff3838" }}> (퇴사)</em>
                        )}
                      </h5>
                    </LaborCardContent>
                  </LaborCard>
                ))
              )}
            </div>
          }
        </SideModal>

        {/*출역인원 출역시간 및 출퇴근 사진 side modal*/}
        <SideModal
          opend={opendDetailLabor}
          closeModal={() => {
            setOpendDetailLabor(false);
            setCalendarSelectedDate(null);
          }}
          header={"상세 정보"}
        >
          <LaborDetailContent>
            <figure>
              <h6>출근시간</h6>
              <h5>
                {detailLabor?.start_dt == null
                  ? ""
                  : dayjs(detailLabor?.start_dt)
                      .add(-9, "hour")
                      .format("HH:mm")}
              </h5>
            </figure>
            <figure>
              <h6>퇴근시간</h6>
              <h5>
                {detailLabor?.end_dt == null
                  ? ""
                  : dayjs(detailLabor?.end_dt).add(-9, "hour").format("HH:mm")}
              </h5>
            </figure>
            <figure>
              <h6>실제출근시간</h6>
              <h5
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyItems: "center",
                  width: "calc(100% - 140px)",
                  justifyContent: "space-between",
                }}
              >
                {detailLabor?.actual_start_dt == null ? (
                  ""
                ) : (
                  <>
                    {dayjs(detailLabor?.actual_start_dt)
                      .add(-9, "hour")
                      .format("HH:mm")}
                    <Button
                      style={{
                        backgroundColor: "#3279F5",
                        color: "#fff",
                        marginTop: "0",
                        whiteSpace: "nowrap",
                      }}
                      onClick={() => {
                        setOpendLocation(true);
                        setLocationMode("start");
                      }}
                    >
                      출근 위치
                    </Button>
                  </>
                )}
              </h5>
            </figure>
            <figure>
              <h6>실제퇴근시간</h6>
              <h5
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyItems: "center",
                  width: "calc(100% - 140px)",
                  justifyContent: "space-between",
                  color:
                    detailLabor?.actual_end_dt?.split("T")[0] !==
                    detailLabor?.actual_start_dt?.split("T")[0]
                      ? "#3279F5"
                      : "#000",
                }}
              >
                {detailLabor?.actual_end_dt == null ? (
                  ""
                ) : (
                  <>
                    {dayjs(detailLabor?.actual_end_dt)
                      .add(-9, "hour")
                      .format("HH:mm")}
                    <Button
                      style={{
                        backgroundColor: "#3279F5",
                        color: "#fff",
                        marginTop: "0",
                        whiteSpace: "nowrap",
                      }}
                      onClick={() => {
                        setOpendLocation(true);
                        setLocationMode("end");
                      }}
                    >
                      퇴근 위치
                    </Button>
                  </>
                )}
              </h5>
            </figure>
            <figure></figure>
            <ImageList>
              <ImageCard>
                <div>
                  <h6>프로필 사진</h6>
                  <br />
                  {detailLabor?.profile_photo == null ? (
                    <div />
                  ) : (
                    <ImageItem
                      src={detailLabor?.profile_photo}
                      onClick={() => {
                        setOpendImageIndex(0);
                        setOpendImage(true);
                      }}
                    ></ImageItem>
                  )}
                </div>
                <div>
                  <h6>출근 사진</h6>
                  <br />
                  {detailLabor?.photo_start == null ? (
                    <div />
                  ) : (
                    <ImageItem
                      src={detailLabor?.photo_start}
                      onClick={() => {
                        setOpendImageIndex(1);
                        setOpendImage(true);
                      }}
                    ></ImageItem>
                  )}
                </div>
                <div>
                  <h6>퇴근 사진</h6>
                  <br />
                  {detailLabor?.photo_end == null ? (
                    <div />
                  ) : (
                    <ImageItem
                      src={detailLabor?.photo_end}
                      onClick={() => {
                        setOpendImageIndex(2);
                        setOpendImage(true);
                      }}
                    ></ImageItem>
                  )}
                </div>
              </ImageCard>
            </ImageList>

            <hr />
            <figure>
              <h6>근태현황</h6>
            </figure>
            <CalendarDetailContainer>
              <AttendanceCalendar
                data={detailLabor}
                newDate={selectedDate}
                handleAttendanceInfo={handleAttendanceInfo}
                handleAttendanceList={handleAttendanceList}
              />
            </CalendarDetailContainer>
            {calendarSelectedDate &&
              selectedWorkdt(calendarSelectedDate)?.length > 0 && (
                <TableContainer className={"calendar-table"}>
                  <TableWrapper>
                    <Table id={"report-table"}>
                      <thead>
                        <tr>
                          <th colSpan={4}>출역공수</th>
                          <th rowSpan={2}>사유</th>
                        </tr>
                        <tr>
                          <th>오전</th>
                          <th>오후</th>
                          <th>야간</th>
                          <th className={"border"}>합계</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedWorkdt(calendarSelectedDate)?.map((e, i) => (
                          <tr key={i}>
                            <td>{e.man_day_am}</td>
                            <td>{e.man_day_pm}</td>
                            <td>{e.man_day_night}</td>
                            <td>
                              {e.man_day_night + e.man_day_am + e.man_day_pm}
                            </td>
                            <td width={"180"} style={{ textAlign: "left" }}>
                              {e.reason}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </TableWrapper>
                </TableContainer>
              )}
          </LaborDetailContent>
        </SideModal>

        <ImageEnlargedModal
          opend={opendImage}
          closeModal={() => {
            setOpendImage(false);
          }}
          handleImageIndex={handleImageIndex}
        >
          {imageArr[opendImageIndex]?.src ? (
            <img
              src={imageArr[opendImageIndex]?.src}
              alt="EnlargedImage"
              style={{
                maxWidth: "70%",
                maxHeight: "80%",
                marginLeft: "10px",
              }}
            />
          ) : (
            <img />
          )}
        </ImageEnlargedModal>

        {/*manage*/}
        <Modal
          opend={openedManage}
          closeModal={() => setOpenedManage(false)}
          header={`${selectedSiteNm} ${sideData?.name}`}
          okText={"수정하기"}
          widthCheck={"60%"}
          formRef={formRef}
        >
          <FormProvider {...methods}>
            <NewAttendanceForm
              selectedAllDates={selectedAllDates}
              formRef={formRef}
              selectedDate={moment.utc(selectedDate).format("YYYY-MM")}
              user_seq={sideData?.user_seq}
              user_site_seq={sideData?.user_site_seq}
              formFinish={formFinish}
              site_seq={selectedSite}
            ></NewAttendanceForm>
          </FormProvider>
        </Modal>

        {/* 위치 팝업 */}
        <Modal
          opend={opendLocation}
          closeModal={() => setOpendLocation(false)}
          header={`${locationMode === "start" ? "출근" : "퇴근"} 위치 확인`}
          cancelOnly={true}
          okText={"닫기"}
          cancelText={""}
          widthCheck={"800px"}
        >
          <>
            <NaverMapLocation
              data={detailLabor}
              mode={locationMode}
            ></NaverMapLocation>
            <span className="info" style={{ marginTop: "8px" }}>
              * 과거 출역정보의 경우, 현재 기준의 현장 주소가 적용되어 차이가 날
              수 있습니다.
            </span>
          </>
        </Modal>
      </div>
    </AxiosNavigate>
  );
}

const Content = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 992px) {
    flex-direction: row;
  }
`;
const GreenButton = styled(ButtonComponent)`
  height: 32px;
  background: #569674;
  font-size: 13px;
  padding: 0px 12px;
`;
const Info = styled.div`
  padding: 0 32px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media only screen and (min-width: 992px) {
    flex-direction: column;
  }
`;

const CalendarContainer = styled.div`
  width: 100%;
  padding: 20px 24px 32px 24px;
  border-radius: 16px;
  border: 1px solid #e2e8f0;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);

  @media only screen and (min-width: 768px) {
    width: 30%;
  }

  @media only screen and (min-width: 992px) {
    width: 280px;
  }
`;
const CalendarDetailContainer = styled.div`
  width: 100%;
  background: #fff;

  @media only screen and (min-width: 992px) {
    width: 376px;
  }
`;
const SiteContainer = styled.div`
  width: 100%;
  border-radius: 16px;
  border: 1px solid #e2e8f0;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
  height: 333px;
  padding: 20px 24px 32px 24px;
  overflow: hidden;
  margin-top: 24px;

  @media only screen and (min-width: 768px) {
    width: 52%;
    margin-top: 0;
    margin-left: 24px;
  }

  @media only screen and (min-width: 992px) {
    width: 280px;
    margin-top: 24px;
    margin-left: 0;
  }
`;

const LaborCard = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  &:hover {
    background-color: #ebf2fe !important;
    border-radius: 4px;
  }
`;

const LaborCardContent = styled.div`
  h5 {
    margin: revert;
  }
`;

const EmptyLaborCard = styled.div``;

const Button = styled.div`
  display: flex;
  height: 32px;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  background: #fff;
  color: #4a5568;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  cursor: pointer;
`;

const Board = styled.div`
  border-radius: 16px;
  background: #f7fafc;
  width: 100%;
  margin-top: 24px;

  @media only screen and (min-width: 992px) {
    margin-top: 0;
  }
`;

const Panel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 32px;
`;

const Search = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #cbd5e0;
  overflow: hidden;
  background: #fff;
`;

const SearchIcon = styled.img`
  padding-left: 12px;
`;

const SearchInput = styled.input`
  box-sizing: border-box;
  width: 100%;
  border: 0px;
  outline: none;

  padding: 12px;

  color: #171923;
  leading-trim: both;
  text-edge: cap;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  :focus {
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
`;

const BoardButton = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
  min-height: 32px;
  padding: 0 9px;
  justify-content: center;
  align-items: center;
  gap: 9px;
  border-radius: 6px;
  border: 1.125px solid #e2e8f0;
  background: #fff;

  color: #4a5568;
  leading-trim: both;
  text-edge: cap;
  font-size: 14px;
  cursor: pointer;

  margin-top: 5px;
`;

const Select = styled(ReactSelect)`
  color: #4a5568;
  leading-trim: both;
  text-edge: cap;
  font-size: 14px;
  border-radius: 6px;
  background: #fff;
  margin-top: 5px;

  div {
    cursor: pointer;
    color: #4a5568;
    border-color: #e2e8f0;
  }
`;

const Icon = styled.img`
  width: 16px;
`;

const TableContainer = styled.div`
  padding: 0 32px;
  margin-top: 10px;

  &.calendar-table {
    padding: 0;
    margin-top: 0;

    > div {
      border-radius: 0;
    }

    td {
      text-align: center;
      white-space: break-spaces;
    }
  }
`;

const TableWrapper = styled.div`
  border: 1px solid #cbd5e0;
  border-radius: 8px;
  overflow: hidden;
  overflow-x: auto;
`;

const Table = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;

  th,
  td {
    text-align: left;
    //padding: 8px;
    font-size: 13px;
    font-weight: 300;
    border-right: 1px solid #cbd5e0;
    border-bottom: 1px solid #cbd5e0;
    white-space: nowrap;
  }

  th {
    background-color: #e2e8f0;
    text-align: center;
  }

  tr:last-child td {
    border-bottom: none;
  }

  td:first-of-type {
    text-align: center;
  }

  td {
    background: #fff;
  }
`;

const SelectWrapper = styled.div``;

const BoardHeader = styled.div`
  margin-bottom: 14px;
  margin-top: 28px;

  h2,
  h5 {
    margin: 0;
  }

  h5 {
    margin-top: 4px;
  }
`;

const SideContent = styled.div`
  width: 90%;
  overflow: auto;
  padding-top: 20px;
  padding-bottom: 24px;
  padding-left: 20px;
  padding-right: 20px;
  background: #f7fafc;
  border-radius: 12px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;
  overflow-y: hidden;

  figure {
    margin: 0;
    margin-top: 12px;
    display: flex;
    align-items: center;
    height: 34px;
  }

  h6,
  h5 {
    margin: 0;
  }

  h6 {
    color: #718096;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    width: 140px;
  }

  h5 {
    color: rgba(0, 0, 0, 0.92);
    font-size: 16px;
    font-family: Pretendard;
    font-weight: 600;
    line-height: 22px;
    word-wrap: break-word;
  }

  hr {
    background: #edf2f7;
    color: #edf2f7;
    border: 1px solid #edf2f7;
    margin: 12px 0;
  }
`;

const Input = styled.input`
  display: inline-block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-sizing: border-box;
  width: 100%;
  border: 0px;
  outline: none;
  padding: 8px 12px;

  color: #171923;
  leading-trim: both;
  text-edge: cap;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  background: #fff;
  border: 1px solid #cbd5e0;
  border-radius: 6px;
  margin-bottom: 4px;
  margin-top: 6px;
`;

const MoreInfoButton = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
  min-height: 32px;
  padding: 0 9px;
  justify-content: center;
  align-items: center;
  gap: 9px;
  border-radius: 6px;
  border: 1.125px solid #e2e8f0;
  background: #fff;

  color: #4a5568;
  leading-trim: both;
  text-edge: cap;
  font-size: 14px;
  cursor: pointer;

  margin-top: 5px;
`;

const LaborDetailContent = styled.div`
  figure {
    margin: 0;
    display: flex;
    align-items: center;
    height: 34px;
  }

  h6,
  h5 {
    margin: 0;
  }

  h6 {
    color: #718096;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    width: 140px;
  }

  h5 {
    color: rgba(0, 0, 0, 0.92);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  hr {
    background: #edf2f7;
    color: #edf2f7;
    border: 1px solid #edf2f7;
    margin: 12px 0;
  }
`;

const ImageList = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 14px;
`;

const ImageCard = styled.div`
  display: flex;
  flex-direction: row;
  //gap: 1px 10px;
  justify-content: space-between;
  width: 30%;
`;

const ImageItem = styled.img`
  width: 75%;
  border-radius: 6px;
  border: 1px solid black;
  cursor: pointer;
`;
export default LaborAttendance;
