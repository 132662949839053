import React, { forwardRef, useRef, useEffect } from "react";
import styled from "@emotion/styled";
import { TablePrintStyle } from "../../library/styled-components";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
const AttendanceMonthPrint = forwardRef((props, ref) => {
  const {
    day,
    data,
    getMonthlyCommuteData,
    setPrint,
    days,
    startDay,
    selectedDate,
  } = props;
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onPrintError: (error) => console.error("프린트 오류:", error),
    documentTitle: "월별 출역 내역",
    onAfterPrint: (event) => {
      setPrint(false);
    },
  });
  useEffect(() => {
    handlePrint();
  }, [componentRef]);
  return (
    <Container ref={componentRef}>
      <Header>
        <Title>월별 출역 내역</Title>
        <span>{`조회기간 : ${moment(startDay).format(
          "YYYY월 MM월 DD일"
        )} ~ ${moment(selectedDate)
          .endOf("month")
          .format("YYYY월 MM월 DD일")}`}</span>
      </Header>

      <TablePrintStyle style={{ marginTop: "10px" }}>
        <TableWrapper className="table_wrapper">
          <table>
            <thead>
              <tr>
                <th rowSpan={2} width={"70px"}>
                  성명
                </th>
                <th rowSpan={2}>현장</th>
                <th rowSpan={2} width={"30px"}>
                  근태
                </th>
                <th colSpan={days?.length}>
                  {`${startDay} ~ ${moment(selectedDate)
                    .endOf("month")
                    .format("YYYY-MM-DD")}`}
                </th>
              </tr>
              <tr>
                {days &&
                  days?.map((e, i) => (
                    <th className="value" key={e.date}>
                      {e.date}
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {getMonthlyCommuteData &&
                getMonthlyCommuteData.map((e, i) => (
                  <React.Fragment key={i}>
                    <tr>
                      <td
                        rowSpan={3}
                        style={{ width: "40px", minWidth: "40px" }}
                        className={` ${
                          getMonthlyCommuteData.length === i + 1 &&
                          "none-border"
                        } spaces`}
                      >
                        {e.userNm}
                      </td>
                      <td
                        rowSpan={3}
                        className={` ${
                          getMonthlyCommuteData.length === i + 1 &&
                          "none-border"
                        } spaces print_border table-left`}
                      >
                        {e.siteNm}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          width: "30px",
                          minWidth: "30px",
                          borderBottomStyle: "dotted",
                        }}
                      >
                        출근
                      </td>
                      {days &&
                        days.map((item) => (
                          <td
                            key={item.date}
                            className="value"
                            style={{
                              borderBottomStyle: "dotted",
                              color:
                                e.commuteTime.find(
                                  (info) => info.workDt === item.allDate
                                )?.startDt &&
                                e.commuteTime.find(
                                  (info) => info.workDt === item.allDate
                                )?.endDt &&
                                e.commuteTime.find(
                                  (info) => info.workDt === item.allDate
                                )?.salaryType !== "D" &&
                                (!e.commuteTime.find(
                                  (info) => info.workDt === item.allDate
                                )?.actualStartDt ||
                                  !e.commuteTime.find(
                                    (info) => info.workDt === item.allDate
                                  )?.actualEndDt)
                                  ? "#ff3838"
                                  : "#000",
                            }}
                          >
                            {e.commuteTime.find(
                              (info) => info.workDt === item.allDate
                            )?.startDt &&
                              moment(
                                e.commuteTime.find(
                                  (info) => info.workDt === item.allDate
                                )?.startDt
                              ).format("HH:mm")}
                          </td>
                        ))}
                    </tr>
                    <tr>
                      <td
                        style={{
                          width: "30px",
                          minWidth: "30px",
                        }}
                      >
                        퇴근
                      </td>
                      {days &&
                        days.map((item) => (
                          <td
                            key={item.date}
                            className="value"
                            style={{
                              color:
                                e.commuteTime.find(
                                  (info) => info.workDt === item.allDate
                                )?.startDt &&
                                e.commuteTime.find(
                                  (info) => info.workDt === item.allDate
                                )?.endDt &&
                                e.commuteTime.find(
                                  (info) => info.workDt === item.allDate
                                )?.salaryType !== "D" &&
                                (!e.commuteTime.find(
                                  (info) => info.workDt === item.allDate
                                )?.actualStartDt ||
                                  !e.commuteTime.find(
                                    (info) => info.workDt === item.allDate
                                  )?.actualEndDt)
                                  ? "#ff3838"
                                  : e.commuteTime
                                      .find(
                                        (info) => info.workDt === item.allDate
                                      )
                                      ?.endDt?.split(" ")[0] >
                                    moment(
                                      `${selectedDate}-${item.date}`
                                    ).format("YYYY-MM-DD")
                                  ? "blue"
                                  : "#000",
                            }}
                          >
                            {e.commuteTime.find(
                              (info) => info.workDt === item.allDate
                            )?.endDt &&
                              moment(
                                e.commuteTime.find(
                                  (info) => info.workDt === item.allDate
                                )?.endDt
                              ).format("HH:mm")}
                          </td>
                        ))}
                    </tr>
                  </React.Fragment>
                ))}
            </tbody>
          </table>
        </TableWrapper>
      </TablePrintStyle>
    </Container>
  );
});

const TableWrapper = styled.div`
  .value {
    width: 26px;
    min-width: 26px;
    font-size: 8px;
    padding: 1px;
  }

  .th_width {
    width: 50px;
    min-width: 50px;
  }

  th,
  td {
    box-sizing: border-box;
    text-align: center;
  }
  .font {
    font-size: 8px;
  }
  thead {
    height: unset;
  }

  tbody tr:last-of-type td {
    border-bottom: none;
  }
`;
const Container = styled.div`
  background: #fff;

  @page {
    size: A4 landscape !important;
    margin-top: 20px;
    margin-bottom: 25px;
    display: block;
    @bottom-right {
      content: counter(page);
    }
  }
  @media print {
    display: block;
    padding: 20px 24px;
    table th {
      background-color: #edf2f7 !important;
      -webkit-print-color-adjust: exact;
    }

    footer {
      position: fixed;
      bottom: 0;
      width: 100%;
      text-align: center;
      font-size: 12px;
    }
  }
`;

const Header = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: center;
  position: relative;
  span {
    font-size: 12px;
    position: absolute;
    right: 0;
    bottom: 0;
  }
`;
const Title = styled.div`
  color: #171923;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  /* line-height: 30px; */
  text-align: center;
  /* margin-bottom: 6px; */
`;

export default AttendanceMonthPrint;
