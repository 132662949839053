import styled from "@emotion/styled";
import ReactSelect from "react-select";

// 입력칸
export const InputBox = styled.div`
  h6 {
    color: #718096;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    //  line-height: 20px;
    margin: 0;

    span {
      color: #ff3838;
    }
  }

  h5 {
    color: #171923;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    //margin: 8px;
    margin-left: 8px;
    margin-bottom: 12px;
    margin-top: 15px;

    span {
      color: #ff3838;
    }
  }

  input {
    display: inline-block;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-sizing: border-box;
    width: 100%;
    border: 0px;
    outline: none;

    padding: 8px 12px;

    color: #171923;
    leading-trim: both;
    text-edge: cap;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    background: #fff;
    border: 1px solid #cbd5e0;
    border-radius: 6px;
    margin-bottom: 8px;
    margin-top: 8px;

    :focus {
    }
  }

  textarea {
    width: 100%;
    min-height: 80px;
    border-radius: 6px;
    border: 1px solid #cbd5e0;
    box-sizing: border-box;
    // margin-top: 6px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    resize: none;
    padding: 8px 12px;
    color: #171923;
    leading-trim: both;
    text-edge: cap;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  width: ${(props) => (props.fulled ? `100%` : "48%")};
  @media only screen and (max-width: 992px) {
    width: 100%;
  }
`;

export const Select = styled(ReactSelect)`
  color: #4a5568;
  leading-trim: both;
  text-edge: cap;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  font-weight: 600;
  border-radius: 6px;
  background: #fff;
  margin-top: 7px;
  margin-bottom: 7px;

  .react-select__control {
    cursor: pointer;
    color: #4a5568;
    border-color: #e2e8f0;

    &:hover {
      border-color: #e2e8f0;
    }

    &:focus {
      border-color: transparent !important; // 선택 시 테두리 색상 투명하게
      box-shadow: none !important; // 선택 시 박스 그림자 제거
    }
  }

  div {
    cursor: pointer;
    color: #4a5568;
    border-color: #e2e8f0;

    &:hover {
      border-color: #e2e8f0;
    }
  }
`;

export const RightContent = styled.div`
  figure {
    margin: 0;
    display: flex;
    align-items: center;
    min-height: 34px;
  }

  h6,
  h5 {
    margin: 0;
  }

  h6 {
    color: #718096;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    width: 120px;
  }

  h5 {
    color: rgba(0, 0, 0, 0.92);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  hr {
    background: #edf2f7;
    color: #edf2f7;
    border: 1px solid #edf2f7;
    margin: 12px 0;
  }
`;

export const PageGroup = styled.div`
  display: flex;
  justify-content: center;
  margin: auto;

  ul {
    display: flex;
    padding: 0;
    list-style: none;
  }

  li a {
    cursor: pointer;
    color: #000;
    width: 30px;
    height: 30px;
    align-items: center;
    justify-content: center;
    display: flex;
    text-decoration: none;
    font-size: 12px;
  }

  li.active a {
    color: #fff;
    background-color: #3279f5 !important;
    border-radius: 4px;
  }

  li:hover:not(.active) a {
    background-color: #ebf2fe !important;
    border-radius: 4px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 13px;

  figure {
    margin: 0;
    display: flex;
    align-items: baseline;
    // min-height: 34px;
  }

  h6,
  h5 {
    margin: 0;
  }

  h6 {
    color: #718096;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    min-width: 120px;
  }

  h5 {
    color: rgba(0, 0, 0, 0.92);
    font-size: 14px;
    font-style: normal;
    white-space: pre-wrap;
    font-weight: 500;
    line-height: 20px;
    word-break: break-all;
  }

  hr {
    background: #edf2f7;
    color: #edf2f7;
    border: 1px solid #edf2f7;
    margin: 12px 0;
  }
`;

export const TablePrintStyle = styled.div`
  .table_wrapper {
    border-radius: 8px;
    overflow: hidden;
    overflow-x: auto;
    border: 1px solid #000;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    box-sizing: border-box;

    th,
    td {
      text-align: left;
      font-size: 9px;
      white-space: nowrap;
      text-align: center;
      border-bottom: 1px solid #000;
      border-right: 1px solid #000;
      padding: 2px;
      box-sizing: border-box;

      &:not(:last-child) {
        //border-bottom: none;
      }
    }

    th {
      background-color: #edf2f7;
      color: #4a5568;
      font-style: normal;
      font-weight: 500;
      //line-height: 12px;
    }

    td {
      color: #1a202c;
      font-weight: normal;
    }

    .none td,
    .none th {
      border-bottom: none;
    }

    tfoot th {
      border-bottom: none;
      font-weight: bold;
      text-align: right;
    }

    .line {
      border-bottom-style: dotted;
    }

    td:last-of-type,
    th:last-of-type {
      border-right: none;
    }

    th.right {
      border-right: 1px solid #000;
    }
  }
`;

export const FieldGroup = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 10px;

  > div {
    row-gap: 5px;
    display: flex;
    flex-direction: column;
  }

  textarea {
    width: 100%;
    min-height: 80px;
    border-radius: 6px;
    border: 1px solid #cbd5e0;
    box-sizing: border-box;
    // margin-top: 6px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    resize: none;
    padding: 8px 12px;
    color: #171923;
    leading-trim: both;
    text-edge: cap;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  input {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }

  label {
    margin-left: 0;

    > span,
    input {
      padding: 0;
    }

    .MuiFormControlLabel-label {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .editor-wrap {
    // min-height: 488px;

    .jodit .jodit-workplace .jodit-wysiwyg {
      min-height: 400px !important;
    }

    .jodit-container.jodit.jodit_theme_default.jodit-wysiwyg_mode {
      background-color: #fff !important;
    }

    p {
      margin: 0 !important;
    }

    .jodit-add-new-line,
    .jodit-status-bar {
      display: none;
    }
  }
`;
export const TableStyle = styled.div`
  padding: 0 32px;
  margin-top: 28px;
  > div {
    border: 1px solid #cbd5e0;
    border-radius: 8px;
    overflow: hidden;
    overflow-x: auto;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;

    thead {
      height: 31px;
    }
  }

  th,
  td {
    text-align: left;
    padding: 2px;
    font-size: 13px;
    font-weight: 300;
    border-right: 1px solid #cbd5e0;
    border-bottom: 1px solid #cbd5e0;
    white-space: nowrap;
    box-sizing: border-box;
  }

  th {
    background-color: #f7fafc;
  }

  tr:last-child td {
    border-bottom: none;
  }
`;

export const WidthStyle = styled.td`
  width: ${(props) => props.width};
  min-width: ${(props) => props.width};
  white-space: ${(props) =>
    props.space ? "break-spaces" : "nowrap"} !important;
  box-sizing: border-box;
`;
